export const InventoryLotSkuInboundReceiptsDataElements = [
    {
        dBKey: 'ReceiptDT',
        displayName: 'Receipt DT',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'EmailAddr',
        displayName: 'User',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'Container',
        displayName: 'Container',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },

    {
        dBKey: 'PalletBarcode',
        displayName: 'Pallet Barcode',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },
    {
        dBKey: 'CaseBarcode',
        displayName: 'Case Barcode',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },
    {
        dBKey: 'LotCode',
        displayName: 'Lot Code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },

    
    {
        dBKey: 'SerialNumber',
        displayName: 'Serial Number',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'Project',
        displayName: 'Project',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },



    {
        dBKey: 'CustomerRef',
        displayName: 'Customer Ref',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'CasesRecovered',
        displayName: 'Cases Recovered',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },


    {
        dBKey: 'UnitsRecovered',
        displayName: 'Units Recovered',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },







]


export default InventoryLotSkuInboundReceiptsDataElements

