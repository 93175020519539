import React, {
    PureComponent
} from 'react';
import Loader from 'react-loader-spinner'
import { withRouter } from 'react-router-dom';
import { Row, Col, Panel, PanelGroup, Button, Timeline, Table } from 'rsuite';
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import RTableGeneral from '../../../componentsGeneral/Rsuite/Table/RTableGeneral';
import InventorySkuWarehouseLocationDataElements from '../../../dataComponentsInventory/InventorySkuWarehouseLocationDataElements';
import queryString from 'query-string';
import { queryStringParse, replaceNegativeWithZero, isNumeric, formatStringForAPI, uniqueArray, convertNanToZero, formatTrueFalseToBit, formatNumberForAPI, formatAllTextNumbersSymbolsForAPI } from '../../../../resources/logic/functions';
import InventoryLoadSkuDetailsDataElements from '../../../dataComponentsInventory/InventoryLoadSkuDetailsDataElements';
// import EditSingleWarehouseLocationsDrawer from './EditSingleWarehouseLocationsDrawer';
import EditSkuDetailsDrawer from './EditSkuDetailsDrawer';
import InventorySkuWarehouseDataElements from '../../../dataComponentsInventory/InventorySkuWarehouseDataElements';
// import InventorySkuWarehouseGroupedDataElements from '../../dataComponentsInventory/InventorySkuWarehouseGroupedDataElements';
import InventoryCaseWarehouseDataElements from '../../../dataComponentsInventory/InventoryCaseWarehouseDataElements';
import _ from 'lodash';
import CaseHistoryDiv from '../../_Divs_Lot_History/CaseHistoryDiv';
import CaseSKUsHistoryDiv from '../../_Divs_Lot_History/CaseSKUsHistoryDiv';
import LoadCaseHistoryDiv from '../../_Divs_Lot_History/LoadCaseHistoryDiv';
import InventoryPalletWarehouseGroupedDataElements from '../../../dataComponentsInventory/InventoryPalletWarehouseGroupedDataElements';
import InventoryCaseWarehouseGroupedDataElements from '../../../dataComponentsInventory/InventoryCaseWarehouseGroupedDataElements';
import InventoryUnitWarehouseGroupedDataElements from '../../../dataComponentsInventory/InventoryUnitWarehouseGroupedDataElements';
import InventoryLotSkuInboundReceiptsDataElements from '../../../dataComponentsInventory/InventoryLotSkuInboundReceiptsDataElements';


const { Column, HeaderCell, Cell } = Table;

const tableIdCol = "CaseSkuID"

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const mountRef = React.createRef();

class LotSkuDetail extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            sortTableColumn: '',
            sortTableType: null,

            // Table Check Box
            checkedKeys: [],

            //Build Load Drawer
            BuildLoadDict: {},
            LocationDict: {},
            NewLoadData: {},
            unsavedBuildLoadChanges: false,
            showBuildLoadDrawer: false,
            existingDataChangeLoading: false,

            //Edit Warehouse Location
            showEditSingleWarehouseLocationsDrawer: false,
            unsavedEditWarehouseLocationValues: false,
            LocationOriginalDict: {},
            LocationToEditDict: {},
            newWarehouseLocationID: null,
            newUnits: 0,
            newCases: 0,
            newPallets: 0,
            newOversize: 0,


            //Edit Po Values
            showEditSkuDetailsDrawer: false,
            unsavedEditSkuValues: false,
            // Po: null,
            CustomerRef: null,
            SKU: null,
            Project: null,
            // ExpectedCases: null,
            // ExpectedPallets: null,
            Notes: null,
        };


        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);


        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        this.handleTableSortColumn = this.handleTableSortColumn.bind(this);

        // Warehouse Location Details
        this.onClickWarehouseLocationDetails = this.onClickWarehouseLocationDetails.bind(this);

        this.onClickLoadDetails = this.onClickLoadDetails.bind(this);




        //Edit Warehouse Location
        this.onclickEditWarehouseLocation = this.onclickEditWarehouseLocation.bind(this);
        this.closeEditSingleWarehouseLocationsDrawer = this.closeEditSingleWarehouseLocationsDrawer.bind(this);
        this.setStateCloseEditSingleWarehouseLocationsDrawer = this.setStateCloseEditSingleWarehouseLocationsDrawer.bind(this);
        // this.onClickAddLocation = this.onClickAddLocation.bind(this);
        // this.handleAddNewLocation = this.handleAddNewLocation.bind(this);
        this.onClickSaveEditWarehouseLocation = this.onClickSaveEditWarehouseLocation.bind(this);


        //Edit Po Drawer
        this.closeEditSkuDrawer = this.closeEditSkuDrawer.bind(this);
        this.onClickConfirmEditSku = this.onClickConfirmEditSku.bind(this);
        this.setStateCloseAddNewDrawer = this.setStateCloseAddNewDrawer.bind(this)

        this.onChangeCustomerRef = this.onChangeCustomerRef.bind(this)
        this.onChangeSKU = this.onChangeSKU.bind(this)
        this.onChangeProject = this.onChangeProject.bind(this)
        this.onChangeNotes = this.onChangeNotes.bind(this)


        //Build Load Drawer
        this.onChangeBuildLoadDrawer = this.onChangeBuildLoadDrawer.bind(this);
        this.onChangeLoad = this.onChangeLoad.bind(this);
        this.onChangeLocation = this.onChangeLocation.bind(this);
        this.onClickBuildLoad = this.onClickBuildLoad.bind(this);
        this.closeBuildLoadDrawer = this.closeBuildLoadDrawer.bind(this);
        this.onClickBuildLoadDrawerSave = this.onClickBuildLoadDrawerSave.bind(this);
        this.onClickRemoveFromBuild = this.onClickRemoveFromBuild.bind(this);

        this.onClickRemoveFromAdd = this.onClickRemoveFromAdd.bind(this);

        // this.onClickPalletDetails = this.onClickPalletDetails.bind(this);
        // this.onClickCaseDetails = this.onClickCaseDetails.bind(this);

        this.onClickPalletDetails = this.onClickPalletDetails.bind(this);
        this.onClickCaseDetails = this.onClickCaseDetails.bind(this);
        this.onClickUnitDetails = this.onClickUnitDetails.bind(this);
    }




    componentDidMount() {
        this.loadSortFromStorage()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }

        if (!_.isEqual(prevProps.InventorySkuRefData, this.props.InventorySkuRefData)) {
            try {
                var skuWarehouseID = this.props.InventorySkuRefData[0].WarehouseID
                var skuCustomerID = this.props.InventorySkuRefData[0].CustomerID

                this.props.inventoryCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, skuWarehouseID, skuCustomerID)
                this.props.inventoryProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, skuWarehouseID, skuCustomerID)
            }
            catch (e) { }
        }
        // if (prevProps.InventorySkuRefData !== this.props.InventorySkuRefData) {
        //     try {
        //         var skuWarehouseID = this.props.InventorySkuRefData[0].WarehouseID
        //         var skuCustomerID = this.props.InventorySkuRefData[0].CustomerID

        //         this.props.inventoryCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, skuWarehouseID, skuCustomerID)
        //         this.props.inventoryProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, skuWarehouseID, skuCustomerID)
        //     }
        //     catch (e) { }
        // }


        if (prevProps.InventorySkuPostSuccessful !== this.props.InventorySkuPostSuccessful && this.props.InventorySkuPostSuccessful) {
            this.setStateCloseAddNewDrawer()
        }
    }


    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }


    //Change State
    onChangeState(e, statename) {
        this.setState({
            [statename]: e
        });
    }




    // Table Check Box
    handleCheckAll(value, checked, data) {
        var rowID
        rowID = 'CaseSkuID'

        const checkedKeys = checked ? [] : data.map(item => item[rowID]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        var rowID
        var currentCheckedRow


        rowID = 'CaseSkuID'
        currentCheckedRow = this.props.InventorySkuWarehouseData.filter(x => value === x[rowID])



        var currentStatusID

        currentStatusID = currentCheckedRow[0]['CaseStatusID']


        if (currentStatusID == '1' || currentStatusID == '2') {
            const { checkedKeys } = this.state;
            const nextCheckedKeys = checked
                ? [...checkedKeys, value]
                : checkedKeys.filter(item => item !== value);
            this.setState({
                checkedKeys: nextCheckedKeys
            });
        }
        else {
            window.alert('Nothing remaining to load.')
        }


    }




    //Table buttons
    onClickWarehouseLocationDetails(e, rowData, history) {
        try {
            var paramID = rowData["WarehouseLocationID"]
            var paramWarehouseID = rowData["WarehouseID"]
            history.push({
                pathname: 'warehouselocationdetails',
                search: 'warehouselocationid=' + paramID + '&' +
                    'warehouseid=' + paramWarehouseID
            });
        }
        catch (e) {
        }
    }



    //On Click Details
    onClickLoadDetails(e, rowData, history) {
        try {
            var paramID = rowData["LoadID"]
            var paramWarehouseID = rowData["WarehouseID"]
            var paramCustomerID = rowData["CustomerID"]
            history.push({
                pathname: 'lotloaddetails',
                search: 'loadid=\'' + paramID + '\'' + '&' +
                    'warehouseid=' + paramWarehouseID + '&' +
                    'customerid=' + paramCustomerID
            });
        }
        catch (e) {
        }
    }






    //Warehouse Location Drawer Buttons
    onclickEditWarehouseLocation(e, rowData, history) {
        this.props.userWarehouseLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData.WarehouseID, rowData.CustomerID);

        var origRowOversize = rowData['Oversize']

        this.setState({
            showEditSingleWarehouseLocationsDrawer: true,
            unsavedEditWarehouseLocationValues: false,

            LocationToEditDict: rowData,
            LocationOriginalDict: rowData,
            newWarehouseLocationID: null,
            newUnits: 0,
            newCases: 0,
            newPallets: 0,
            newOversize: origRowOversize
        });

    }





    // check if same location but oversize
    onChangeEditLocationDrawer = (event, label) => {
        if (label === 'newWarehouseLocationID') {
            // find new warehouse name in existing state:
            //This will be changed to dropdown choice:
            if (this.state.LocationOriginalDict['WarehouseLocationID'] === event) {
                if (this.state.LocationOriginalDict['Oversize'] === true) {
                    var newOversize = false
                }
                else {
                    var newOversize = true
                }
                this.setState({
                    newWarehouseLocationID: event,
                    newOversize: newOversize,
                    unsavedEditWarehouseLocationValues: true
                });
            }
            else {
                this.setState({
                    newWarehouseLocationID: event,
                    unsavedEditWarehouseLocationValues: true
                });
            }
        }
        else if (label === 'newOversize') {
            if (this.state.LocationOriginalDict['WarehouseLocationID'] === this.state.newWarehouseLocationID) {
                if (this.state.LocationOriginalDict['Oversize'] === true) {
                    var newOversize = false
                }
                else {
                    var newOversize = true
                }
                this.setState({
                    newOversize: newOversize,
                    unsavedEditWarehouseLocationValues: true
                });
            }
            else {
                this.setState({
                    newOversize: event,
                    unsavedEditWarehouseLocationValues: true
                });
            }
        }
        else {
            this.setState({
                [label]: event,
                unsavedEditWarehouseLocationValues: true
            });
        }
        this.forceUpdate()
    };


    // handleAddNewLocation = (newLocationDict) => {
    //     this.setState({
    //         unsavedEditWarehouseLocationValues: true,
    //         LocationDict: newLocationDict
    //     }, () => {
    //         // Do an API call with this.state.searchTerm
    //     });
    // }

    // onClickAddLocation = (event) => {
    //     //Check if any Warehouse names are null
    //     if (this.state.LocationDict[this.state.LocationToEditDict.SkuID].filter(item => item.WarehouseLocationID === null || item.WarehouseLocationID === '').length > 0) {
    //         window.alert('Please enter all location values first.')
    //     }
    //     else {
    //         //save warehouse name
    //         var currentLocationDict = this.state.LocationDict

    //         currentLocationDict[this.state.LocationToEditDict.SkuID].push({ 'WarehouseLocationID': null, 'Units': null, 'WarehouseID': null, 'Cases': null, 'Pallets': null, 'Oversize': null })

    //         this.handleAddNewLocation(currentLocationDict)

    //         this.forceUpdate()
    //     }
    // }



    onClickSaveEditWarehouseLocation(e) {

        try {
            var warehouseID = this.state.LocationOriginalDict.WarehouseID
            var customerID = this.state.LocationOriginalDict.CustomerID

            var skuID = this.state.LocationOriginalDict.SkuID

            var originalLocationWarehouseID = formatNumberForAPI(this.state.LocationOriginalDict.WarehouseLocationID)
            var originalLocationSkuWarehouseLocID = formatNumberForAPI(this.state.LocationOriginalDict.SkuWarehouseLocationID)
            var originalLocationUnits = convertNanToZero(this.state.LocationOriginalDict.Units) - convertNanToZero(this.state.newUnits)
            var originalLocationCases = convertNanToZero(this.state.LocationOriginalDict.Cases) - convertNanToZero(this.state.newCases)
            var originalLocationPallets = convertNanToZero(this.state.LocationOriginalDict.Pallets) - convertNanToZero(this.state.newPallets)
            var originalLocationOversize = formatTrueFalseToBit(this.state.LocationOriginalDict.Oversize)

            var newLocationID = formatNumberForAPI(this.state.newWarehouseLocationID)
            var newLocationOversize = formatTrueFalseToBit(this.state.newOversize)
            var newLocationUnits = convertNanToZero(this.state.newUnits)
            var newLocationCases = convertNanToZero(this.state.newCases)
            var newLocationPallets = convertNanToZero(this.state.newPallets)

            if ((newLocationUnits === null || newLocationUnits == 0) && (newLocationCases === null || newLocationCases == 0) && (newLocationPallets === null || newLocationPallets == 0)) {
                window.alert('Please select Units, Cases, Pallets to move.')
            }
            else if (newLocationID === null) {
                window.alert('Please select new location.')
            }
            else {
                try {
                    // call Inventory_SKU_Warehouse_Location_Post twice
                    // first time call with old location and send new totals for old location
                    this.props.inventorySkuWarehouseLocationPost(
                        this.props.currentUserEmail,
                        this.props.userSignInData.AuthToken,
                        skuID,
                        originalLocationSkuWarehouseLocID,
                        originalLocationWarehouseID,
                        convertNanToZero(originalLocationUnits),
                        convertNanToZero(originalLocationCases),
                        convertNanToZero(originalLocationPallets),
                        originalLocationOversize,
                        originalLocationWarehouseID,
                        warehouseID,
                        customerID,
                        null,
                        null,
                        null,
                        null
                    )
                    // second time call with new location and new counts
                    this.props.inventorySkuWarehouseLocationPost(
                        this.props.currentUserEmail,
                        this.props.userSignInData.AuthToken,
                        skuID,
                        null,
                        newLocationID,
                        convertNanToZero(newLocationUnits),
                        convertNanToZero(newLocationCases),
                        convertNanToZero(newLocationPallets),
                        newLocationOversize,
                        originalLocationWarehouseID,
                        warehouseID,
                        customerID,
                        null,
                        null,
                        null,
                        null
                    )

                    this.setStateCloseEditSingleWarehouseLocationsDrawer()
                }
                catch (e) { }
            }
        }
        catch (e) {

        }


    }
    closeEditSingleWarehouseLocationsDrawer() {
        if (this.state.unsavedEditWarehouseLocationValues) {
            if (window.confirm('Leave without saving changes?')) {
                this.setStateCloseEditSingleWarehouseLocationsDrawer()
                window.location.reload();
            }
        }
        else {
            this.setStateCloseEditSingleWarehouseLocationsDrawer()
        }
    }
    setStateCloseEditSingleWarehouseLocationsDrawer() {
        this.setState({
            showEditSingleWarehouseLocationsDrawer: false,
            unsavedEditWarehouseLocationValues: false,
            LocationOriginalDict: {},
            LocationToEditDict: {},
            newWarehouseLocationID: null,
            newUnits: 0,
            newCases: 0,
            newPallets: 0,
            newOversize: 0,

        });
    }





    //Edit Po Drawer
    onClickEditSku() {
        this.openEditSku()
        this.setState({
            // Po: this.props.InventorySkuRefData[0].PO,

            CustomerRef: this.props.InventorySkuRefData[0].CustomerRefID,
            SKU: this.props.InventorySkuRefData[0].SKU,
            Project: this.props.InventorySkuRefData[0].ProjectID,
            // ExpectedCases: this.props.InventorySkuRefData[0].CaseCount,
            // ExpectedPallets: this.props.InventorySkuRefData[0].PalletCount,
            Notes: this.props.InventorySkuRefData[0].Notes
        });
    }
    openEditSku(placement) {
        this.setState({
            placement,
            showEditSkuDetailsDrawer: true,
            // unsavedEditSkuValues: true,
        });
    }

    onChangeNotes = (e) => {
        this.setState({
            unsavedEditSkuValues: true,
            Notes: e
        });
    }

    // onChangeCustomerRef = (e, value, item) => {
    //     this.setState({
    //         CustomerRef: e
    //     });
    // }
    // onChangeProject = (e, value, item) => {
    //     this.setState({
    //         Project: e
    //     });
    // }
    onChangeSKU = (e) => {
        this.setState({
            unsavedEditSkuValues: true,
            SKU: e
        });
    }


    // onChangeEditPo = (event, field) => {
    //     var dict = {};
    //     dict[field] = event;
    //     this.setState(
    //         dict
    //     );
    //     this.setState({
    //         unsavedEditSkuValues: true,
    //     });
    // };

    onChangeCustomerRef = (e, value, item) => {
        this.setState({
            unsavedEditSkuValues: true,
            CustomerRef: e
        });
    }

    onChangeProject = (e, value, item) => {
        this.setState({
            unsavedEditSkuValues: true,
            Project: e
        });
    }

    onClickConfirmEditSku(e) {

        this.props.inventorySkuPost(
            this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.InventorySkuRefData[0].SkuID, formatStringForAPI(this.state.SKU), null, null, null, null, null, formatStringForAPI(this.state.CustomerRef), null, formatStringForAPI(this.state.Project), formatAllTextNumbersSymbolsForAPI(this.state.Notes)
        )


    }
    closeEditSkuDrawer() {
        if (this.state.unsavedEditSkuValues) {
            if (window.confirm('Leave without saving changes?')) {
                this.setStateCloseAddNewDrawer()
                window.location.reload();
            }
        }
        else {
            this.setStateCloseAddNewDrawer()
        }
    }
    setStateCloseAddNewDrawer() {
        this.setState({
            showEditSkuDetailsDrawer: false,
            unsavedEditSkuValues: false,

            Po: null,
            CustomerRef: null,
            Project: null,
            ExpectedCases: null,
            ExpectedPallets: null,
            Notes: null,
        });
    }

























    //Build Load Drawer
    onClickBuildLoad(e) {

        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            if (this.state.checkedKeys.length > 0) {
                var buildLoadArray = {};

                var rowID;

                rowID = 'CaseSkuID'

                buildLoadArray = this.props.InventorySkuWarehouseData.filter(x => this.state.checkedKeys.includes(x.CaseSkuID))




                var newLoadDict = {}
                newLoadDict['PickupDate'] = null
                newLoadDict['DeliveryDate'] = null
                newLoadDict['Carrier'] = null
                newLoadDict['BOL'] = null
                newLoadDict['Trailer'] = null
                newLoadDict['Seal'] = null



                this.setState({
                    BuildLoadDict: buildLoadArray,
                    NewLoadData: newLoadDict,
                    showBuildLoadDrawer: true,
                });
            }
            else {
                window.alert('Please select rows.')
            }
        }
        else {
            window.alert('Please select one warehouse and one customer to build a load.')
        }
    }


    onChangeBuildLoadDrawer = (e, item, label) => {

        var rowID

        var currentItem

        rowID = 'CaseSkuID'



        currentItem = item[rowID]

        var currentBuildDict = this.state.BuildLoadDict
        var buildKeyToUpdate = currentBuildDict.findIndex(item => item[rowID] === currentItem)


        currentBuildDict[buildKeyToUpdate][label] = e

        this.setState({
            unsavedBuildLoadChanges: true,
            NewLoadData: currentBuildDict
        });

        this.forceUpdate()



    };


    onChangeSku(e, pallet, cases, skus, label) {


        var palletSeq = pallet['PalletSeq']
        var caseSeq = cases['CaseSeq']
        var skuSeq = skus['SkuSeq']
        var currentPalletDict = this.state.ReceivePalletDict
        var palletKeyToUpdate = currentPalletDict.findIndex(item => item.PalletSeq === palletSeq)
        var caseKeyToUpdate = currentPalletDict[palletKeyToUpdate]['Cases'].findIndex(item => item.CaseSeq === caseSeq)

        var skuKeyToUpdate = currentPalletDict[palletKeyToUpdate]['Cases'][caseKeyToUpdate]['Skus'].findIndex(item => item.SkuSeq === skuSeq)

        currentPalletDict[palletKeyToUpdate]['Cases'][caseKeyToUpdate]['Skus'][skuKeyToUpdate][label] = e
        this.handleSetStatePallet(currentPalletDict)


    }



    onChangeLoad = (e, label) => {
        var loadDict = this.state.NewLoadData
        loadDict[label] = e
        this.setState({
            unsavedBuildLoadChanges: true,
            NewLoadData: loadDict
        });

        this.forceUpdate()



    };
    onChangeLocation = (e, item, locationitem, label, skuWarehouseLocationID) => {
        setTimeout(() => {
            this.setState({
                existingDataChangeLoading: true
            });
        }, 50);

        var skuId = item.SkuID;
        var itemIndex = this.state.LocationDict[skuId].findIndex(({ SkuWarehouseLocationID }) => SkuWarehouseLocationID === skuWarehouseLocationID);
        var newLocationBuildDict = this.state.LocationDict

        newLocationBuildDict[skuId][itemIndex][label] = isNumeric(e)

        var currentBuildLoadDictIndex = this.state.BuildLoadDict.findIndex(({ SkuID }) => SkuID === skuId);
        var currentBuildLoadDict = this.state.BuildLoadDict

        try {
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewUnitsLoaded'] = replaceNegativeWithZero((newLocationBuildDict[currentBuildLoadDict[currentBuildLoadDictIndex]['SkuID']].reduce((a, v) => a = a + v.Units, 0)))
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewCasesLoaded'] = replaceNegativeWithZero((newLocationBuildDict[currentBuildLoadDict[currentBuildLoadDictIndex]['SkuID']].reduce((a, v) => a = a + v.Cases, 0)))
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewPallets'] = replaceNegativeWithZero((newLocationBuildDict[currentBuildLoadDict[currentBuildLoadDictIndex]['SkuID']].reduce((a, v) => a = a + v.Pallets, 0)))
        }
        catch (e) {
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewUnitsLoaded'] = replaceNegativeWithZero(currentBuildLoadDict[currentBuildLoadDictIndex]['UnitsReceived'] - currentBuildLoadDict[currentBuildLoadDictIndex]['UnitsLoaded'])
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewCasesLoaded'] = replaceNegativeWithZero(currentBuildLoadDict[currentBuildLoadDictIndex]['CasesReceived'] - currentBuildLoadDict[currentBuildLoadDictIndex]['CasesLoaded'])
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewPallets'] = replaceNegativeWithZero(currentBuildLoadDict[currentBuildLoadDictIndex]['PalletsReceived'] - currentBuildLoadDict[currentBuildLoadDictIndex]['PalletsLoaded'])
        }

        this.setState({
            unsavedBuildLoadChanges: true,
            BuildLoadDict: currentBuildLoadDict,
            LocationDict: newLocationBuildDict,
            existingDataChangeLoading: false
        });
    }

    onClickRemoveFromAdd = (e, item, locationitem, skuWarehouseLocationID) => {

        this.onChangeLocation(0, item, locationitem, 'Units', skuWarehouseLocationID)
        this.onChangeLocation(0, item, locationitem, 'Cases', skuWarehouseLocationID)
        this.onChangeLocation(0, item, locationitem, 'Pallets', skuWarehouseLocationID)

        this.forceUpdate()
    }

    onClickRemoveFromBuild(e, item) {

        var rowID;
        rowID = 'CaseSkuID'

        var prevBuild = {}
        prevBuild = this.state.BuildLoadDict

        var idToRemove = item[rowID];

        var itemIndex = this.state.BuildLoadDict.findIndex((row) => row[rowID] === idToRemove);

        prevBuild.splice(itemIndex, 1);

        setTimeout(() => {
            this.setState({
                BuildLoadDict: prevBuild
            });
        }, 50);


        this.forceUpdate()

    }
    onClickBuildLoadDrawerSave(e, totalUnitsNumber, totalRecoveredNum, totalPalleteNum) {

        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {


            var loadPalletDict = this.state.BuildLoadDict;

            var filteredLoadPalletDict = loadPalletDict.map(item => {
                const { PalletID, CaseID, CaseSkuID, SkuID, UnitsInWhse } = item;
                let filteredItem = {};
                if (PalletID) filteredItem.PalletID = PalletID;
                if (CaseID) filteredItem.CaseID = CaseID;
                if (CaseSkuID) filteredItem.CaseSkuID = CaseSkuID;
                if (SkuID) filteredItem.SkuID = SkuID;
                if (UnitsInWhse) filteredItem.UnitsInWhse = UnitsInWhse;
                return filteredItem;
            }).filter(item => Object.keys(item).length > 0);
            
            var LoadPalletDictString = JSON.stringify(filteredLoadPalletDict)

            

            // var loadPalletDict = this.state.BuildLoadDict
            // var LoadPalletDictString = JSON.stringify(loadPalletDict)

            try {
                var paramPostLoadPalletDict = "'" + LoadPalletDictString + "'"
            }
            catch (e) {
                var paramPostLoadPalletDict = null
            }


            try {
                if (this.state.NewLoadData.PickupDate != null) {
                    var paramPostPickupDate = "'" + moment(this.state.NewLoadData.PickupDate).format('YYYY-MM-DD') + "'"
                }
                else {
                    var paramPostPickupDate = null
                }
            }
            catch (e) {
                var paramPostPickupDate = null
            }
            try {
                if (this.state.NewLoadData.DeliveryDate != null) {
                    var paramPostDeliveryDate = "'" + moment(this.state.NewLoadData.DeliveryDate).format('YYYY-MM-DD') + "'"
                }
                else {
                    var paramPostDeliveryDate = null
                }
            }
            catch (e) {
                var paramPostDeliveryDate = null
            }
            try {
                // var paramPostCarrier = "'" + this.state.NewLoadData.Carrier + "'"
                var paramPostCarrier = formatStringForAPI(this.state.NewLoadData.Carrier)
            }
            catch (e) {
                var paramPostCarrier = null
            }
            try {
                // var paramPostBOL = "'" + this.state.NewLoadData.BOL + "'"
                var paramPostBOL = formatStringForAPI(this.state.NewLoadData.BOL)
            }
            catch (e) {
                var paramPostBOL = null
            }
            try {
                // var paramPostTrailer = "'" + this.state.NewLoadData.Trailer + "'"
                var paramPostTrailer = formatStringForAPI(this.state.NewLoadData.Trailer)
            }
            catch (e) {
                var paramPostTrailer = null
            }
            try {
                // var paramPostSeal = "'" + this.state.NewLoadData.Seal + "'"
                var paramPostSeal = formatStringForAPI(this.state.NewLoadData.Seal)
            }
            catch (e) {
                var paramPostSeal = null
            }

            try {
                var paramPostDesintation = formatStringForAPI(this.state.NewLoadData.Destination)
            }
            catch (e) {
                var paramPostDesintation = null
            }
            try {
                var paramPostLoadStatusID = 1
            }
            catch (e) {
                var paramPostLoadStatusID = 1
            }

            try {
                var paramReportNav = formatStringForAPI('sku')
            }
            catch (e) {
                var paramReportNav = null
            }


            this.props.inventoryLoadPalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramPostLoadPalletDict, paramReportNav, null, paramPostPickupDate, paramPostDeliveryDate, paramPostCarrier, paramPostBOL, paramPostTrailer, paramPostSeal, null, null, null, paramPostDesintation, null, paramPostLoadStatusID, null, null, null, null, null, null, null)


            this.setState({
                unsavedBuildLoadChanges: false,
                showBuildLoadDrawer: false
            });
            // }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }
    closeBuildLoadDrawer() {
        if (this.state.unsavedBuildLoadChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setBuildLoadDrawerToClose()
            }
        }
        else {
            this.setBuildLoadDrawerToClose()
        }
    }

    setBuildLoadDrawerToClose() {
        this.setState({
            BuildLoadDict: {},
            LocationDict: {},
            NewLoadData: {},
            unsavedBuildLoadChanges: false,
            showBuildLoadDrawer: false
        });
    }











    // Pallet/Case tables
    // handleTableSortColumn = (sortTableColumn, sortTableType) => {
    //     this.setState({ sortTableColumn, sortTableType });
    // }

    handleTableSortColumn(sortTableColumn, sortTableType) {
        this.setState({ loading: true });

        // If the same column was sorted, toggle the sort type
        if (this.state.sortTableColumn === sortTableColumn) {
            sortTableType = this.state.sortTableType === 'asc' ? 'desc' : 'asc';
        }

        // this.saveSortTosessionStorage(sortTableColumn, sortTableType)

        setTimeout(() => {
            this.setState({
                sortTableColumn,
                sortTableType,
                loading: false
            });
        }, 100);
    }

    getTableData = (data) => {
        const { sortTableColumn, sortTableType } = this.state;
        const sortedData = [...data]; // Copy the data

        if (sortTableColumn && sortTableType) {
            sortedData.sort((a, b) => {
                let x = a[sortTableColumn];
                let y = b[sortTableColumn];
                if (typeof x === 'object') {
                    x = x.location.toLowerCase();
                    y = y.location.toLowerCase();
                } else if (typeof x === 'string') {
                    x = x.toLowerCase();
                    y = y.toLowerCase();
                }
                if (sortTableType === 'asc') {
                    return x > y ? 1 : -1;
                } else {
                    return x < y ? 1 : -1;
                }
            });
        }

        return sortedData;


    }



    // onClickPalletDetails(pid) {
    //     var path = 'palletdetails';
    //     var pathid = 'palletid';
    //     var paramID = pid;
    //     this.props.history.push({
    //         pathname: path,
    //         search: pathid + '=\'' + paramID + '\''
    //     });
    // }

    // onClickCaseDetails(cid) {
    //     var path = 'casedetails';
    //     var pathid = 'caseid';
    //     var paramID = cid;
    //     this.props.history.push({
    //         pathname: path,
    //         search: pathid + '=\'' + paramID + '\''
    //     });
    // }



    //save customer selection
    saveCustomerSelectionTosessionStorage(customerToSave) {
        try {
            sessionStorage.setItem('currentCustomer', customerToSave)
        }
        catch (e) {

        }
    }

    //Table on click details
    // onClickDetails(e, rowData, history) {
    //     try {
    //         var paramID = rowData[tableIdCol]
    //         history.push({
    //             pathname: 'skudetails',
    //             search: 'skuid=\'' + paramID + '\''
    //         });
    //     }
    //     catch (e) {
    //     }
    // }

    onClickPalletDetails(e, rowData, history) {
        var newCustID = rowData.CustomerID
        this.saveCustomerSelectionTosessionStorage(newCustID)

        var selectedWarehouseID = rowData.WarehouseLocationID
        var selectedOversize = rowData.Oversize
        var selectedStatus = rowData.PalletStatusID

        let searchParams = new URLSearchParams(this.props.location.search);
        searchParams.set('warehouselocationid', selectedWarehouseID);
        searchParams.set('palletoverize', selectedOversize);
        searchParams.set('reportnav', 'pallet');
        searchParams.set('statusid', selectedStatus);
        searchParams.set('skuid', this.props.InventorySkuRefData[0].SkuID);
        searchParams.set('filtershown', false);
        let newSearchString = searchParams.toString();

        try {
            history.push({
                pathname: 'inventorypcs',
                search: newSearchString
            });

            // window.location.reload();
        }
        catch (e) {
        }
    }

    onClickCaseDetails(e, rowData, history) {

        var newCustID = rowData.CustomerID
        this.saveCustomerSelectionTosessionStorage(newCustID)

        // var newSkuID = rowData.SkuID
        var newSku = rowData.CaseSKU
        var newLotCode = rowData.LotCode
        var newSerial = rowData.SerialNumber
        var selectedWarehouseID = rowData.WarehouseLocationID
        var selectedStatusID = rowData.CaseStatusID

        

        let searchParams = new URLSearchParams(this.props.location.search);
        searchParams.set('warehouselocationid', selectedWarehouseID);
        // searchParams.set('caseskuid', newSkuID);
        searchParams.set('casesku', newSku);
        searchParams.set('caselotcode', newLotCode);
        searchParams.set('caseserial', newSerial);
        searchParams.set('reportnav', 'case');
        searchParams.set('statusid', selectedStatusID);
        searchParams.set('filtershown', false);
        // searchParams.set('casesku', this.props.InventorySkuRefData[0].SKU.toString());
        searchParams.set('skuid', this.props.InventorySkuRefData[0].SkuID);

        let newSearchString = searchParams.toString();

        try {
            history.push({
                pathname: 'inventorypcs',
                search: newSearchString
            });

            // window.location.reload();
        }
        catch (e) {
        }

    }

    onClickUnitDetails(e, rowData, history) {

        var newCustID = rowData.CustomerID
        this.saveCustomerSelectionTosessionStorage(newCustID)

        var newSku = rowData.SKU
        var newLotCode = rowData.LotCode
        var newSerial = rowData.SerialNumber
        var selectedWarehouseID = rowData.WarehouseLocationID
        var selectedStatusID = rowData.SkuStatusID

        let searchParams = new URLSearchParams(this.props.location.search);
        searchParams.set('warehouselocationid', selectedWarehouseID);
        searchParams.set('unitsku', newSku);
        searchParams.set('unitlotcode', newLotCode);
        searchParams.set('unitserial', newSerial);
        searchParams.set('reportnav', 'sku');
        searchParams.set('statusid', selectedStatusID);
        // searchParams.set('unitsku', this.props.InventorySkuRefData[0].SKU.toString());
        searchParams.set('skuid', this.props.InventorySkuRefData[0].SkuID);
        searchParams.set('filtershown', false);

        let newSearchString = searchParams.toString();

        try {
            history.push({
                pathname: 'inventorypcs',
                search: newSearchString
            });

            // window.location.reload();
        }
        catch (e) {
        }
    }

    render() {
        const { checkedKeys, sortColumn, sortType } = this.state;
        const { sortTableColumn, sortTableType } = this.state;


        if (this.props.InventoryLoadPalletCaseSkuPostLoading) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                    <p style={{ marginTop: '20px', fontSize: '20px', color: '#3396E5' }}>Please wait...</p>
                </div>
            );
        }



        if (this.props.InventoryRecoveryPostLoading) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                    <p style={{ marginTop: '20px', fontSize: '20px', color: '#3396E5' }}>Please wait, receiving...</p>
                </div>
            );
        }


        let skuRefDetailsDiv;
        let pageHeader;
        if (this.props.isInventorySkuRefLoading) {
            pageHeader = <div>Inventory Management - SKU</div>

            skuRefDetailsDiv = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>

        }
        else if (typeof (this.props.InventorySkuRefData[0]) !== 'undefined' && this.props.InventorySkuRefData != null) {
            try {
                var skuName = this.props.InventorySkuRefData[0].SKU.toString()
                pageHeader = <div style={{ marginBottom: '15px' }}>  {'Inventory Management - SKU: ' + skuName} </div>
            }
            catch (e) {
                pageHeader = <div>Inventory Management - SKU</div>
            }

            skuRefDetailsDiv =
                <div>
                    <Row >
                        <Col md={24} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }} header={
                                <div style={{ float: 'right' }}>
                                    <Button style={{ float: 'right' }} onClick={(e) => { this.onClickEditSku(e) }} >Edit</Button>
                                </div>
                            }>
                                Warehouse: <strong>{this.props.InventorySkuRefData[0].WarehouseName}</strong>
                                <br />
                                Customer: <strong>{this.props.InventorySkuRefData[0].CustomerName}</strong>
                                <br />
                                Customer Ref: <strong>{this.props.InventorySkuRefData[0].CustomerRef}</strong>
                                <br />
                                Project: <strong>{this.props.InventorySkuRefData[0].Project}</strong>
                                <br />
                                Notes: <strong>{this.props.InventorySkuRefData[0].Notes}</strong>
                                <br />
                            </Panel>

                        </Col>
                    </Row>

                </div>



        }
        else {
            pageHeader = <div>Inventory Management - SKU</div>
            skuRefDetailsDiv = <div></div>
        }














        let skuDetailsDiv;

        if (this.props.isInventorySkuWarehouseGroupedLoading) {
            skuDetailsDiv = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        }

        else if (typeof (this.props.InventorySkuWarehouseGroupedData[0]) !== 'undefined' && this.props.InventorySkuWarehouseGroupedData != null) {

            skuDetailsDiv =
                <div>
                    <Row >
                        <Col md={24} sm={24}>

                            <Panel bordered style={{ marginBottom: 10 }}>

                                Status: <strong>{this.props.InventorySkuWarehouseGroupedData[0].StatusName}</strong>
                                <br />

                            </Panel>


                        </Col>
                    </Row>
                    <Row>

                        {/* <Col md={6} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units Expected: <strong>{this.props.InventorySkuWarehouseGroupedData[0].UnitsExpected}</strong>
                                <br />
                                Cases Expected: <strong>{this.props.InventorySkuWarehouseGroupedData[0].CasesExpected}</strong>
                                <br />
                                Pallets Expected: <strong>{this.props.InventorySkuWarehouseGroupedData[0].PalletsExpected}</strong>
                                <br />
                            </Panel>
                        </Col> */}
                        <Col md={8} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units Received: <strong>{this.props.InventorySkuWarehouseGroupedData[0].UnitsRecovered}</strong>
                                <br />
                                Cases Received: <strong>{this.props.InventorySkuWarehouseGroupedData[0].CasesReceived}</strong>
                                <br />
                                {/* Pallets Received: <strong>{this.props.InventorySkuWarehouseGroupedData[0].PalletsReceived}</strong> */}
                                {/* <br /> */}
                            </Panel>
                        </Col>
                        <Col md={8} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units In Whse: <strong>{this.props.InventorySkuWarehouseGroupedData[0].UnitsInWhse}</strong>
                                <br />
                                Cases In Whse: <strong>{this.props.InventorySkuWarehouseGroupedData[0].CasesInWhse}</strong>
                                <br />
                                {/* Pallets In Whse: <strong>{this.props.InventorySkuWarehouseGroupedData[0].Pallets}</strong> */}
                                {/* <br /> */}
                            </Panel>
                        </Col>
                        <Col md={8} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units Shipped: <strong>{this.props.InventorySkuWarehouseGroupedData[0].UnitsLoaded}</strong>
                                <br />
                                Cases Shipped: <strong>{this.props.InventorySkuWarehouseGroupedData[0].CasesLoaded}</strong>
                                <br />
                                {/* Pallets Shipped: <strong>{this.props.InventorySkuWarehouseGroupedData[0].PalletsLoaded}</strong> */}
                                {/* <br /> */}
                            </Panel>
                        </Col>

                    </Row>
                </div>



        }
        else {
            skuDetailsDiv = <div></div>
        }


        // let skuDetailsDiv;
        // let pageHeader;
        // if (this.props.isInventorySkuRefLoading) {
        //     pageHeader = <div>Inventory Management - SKU</div>

        //     skuDetailsDiv = <div align="center">
        //         <section className="panel">
        //         </section>
        //         <Loader
        //             type="TailSpin"
        //             color="#3396E5"
        //             height="50"
        //             width="50"
        //         />
        //     </div>

        // }
        // else if (typeof (this.props.InventorySkuRefData[0]) !== 'undefined' && this.props.InventorySkuRefData != null) {
        //     try {
        //         var skuName = this.props.InventorySkuRefData[0].SKU.toString()
        //         pageHeader = <div style={{ marginBottom: '15px' }}>  {'Inventory Management - SKU: ' + skuName} </div>
        //     }
        //     catch (e) {
        //         pageHeader = <div>Inventory Management - SKU</div>
        //     }

        //     skuDetailsDiv =
        //         <div>
        //             <Row >
        //                 <Col md={24} sm={24}>
        //                     <Panel bordered style={{ marginBottom: 10 }} header={
        //                         <div style={{ float: 'right' }}>
        //                             <Button style={{ float: 'right' }} onClick={(e) => { this.onClickEditSku(e) }} >Edit</Button>
        //                         </div>
        //                     }>





        //                         Warehouse: <strong>{this.props.InventorySkuRefData[0].WarehouseName}</strong>
        //                         <br />
        //                         Customer: <strong>{this.props.InventorySkuRefData[0].CustomerName}</strong>
        //                         <br />
        //                         Customer Ref: <strong>{this.props.InventorySkuRefData[0].CustomerRef}</strong>
        //                         <br />
        //                         Project: <strong>{this.props.InventorySkuRefData[0].Project}</strong>
        //                         <br />
        //                         Status: <strong>{this.props.InventorySkuRefData[0].SkuStatusName}</strong>
        //                         <br />
        //                         Notes: <strong>{this.props.InventorySkuRefData[0].Notes}</strong>
        //                         <br />
        //                     </Panel>
        //                 </Col>
        //             </Row>
        //             <Row>

        //             InventorySkuWarehouseGroupedData

        //                 {/* <Col md={6} sm={24}>
        //                     <Panel bordered style={{ marginBottom: 10 }}>
        //                         Units Expected: <strong>{this.props.InventorySkuRefData[0].UnitsExpected}</strong>
        //                         <br />
        //                         Cases Expected: <strong>{this.props.InventorySkuRefData[0].CasesExpected}</strong>
        //                         <br />
        //                         Pallets Expected: <strong>{this.props.InventorySkuRefData[0].PalletsExpected}</strong>
        //                         <br />
        //                     </Panel>
        //                 </Col> */}
        //                 <Col md={8} sm={24}>
        //                     <Panel bordered style={{ marginBottom: 10 }}>
        //                         Units Received: <strong>{this.props.InventorySkuRefData[0].UnitsReceived}</strong>
        //                         <br />
        //                         Cases Received: <strong>{this.props.InventorySkuRefData[0].CasesReceived}</strong>
        //                         <br />
        //                         Pallets Received: <strong>{this.props.InventorySkuRefData[0].PalletsReceived}</strong>
        //                         <br />
        //                     </Panel>
        //                 </Col>
        //                 <Col md={8} sm={24}>
        //                     <Panel bordered style={{ marginBottom: 10 }}>
        //                         Units In Whse: <strong>{this.props.InventorySkuRefData[0].Units}</strong>
        //                         <br />
        //                         Cases In Whse: <strong>{this.props.InventorySkuRefData[0].Cases}</strong>
        //                         <br />
        //                         Pallets In Whse: <strong>{this.props.InventorySkuRefData[0].Pallets}</strong>
        //                         <br />
        //                     </Panel>
        //                 </Col>
        //                 <Col md={8} sm={24}>
        //                     <Panel bordered style={{ marginBottom: 10 }}>
        //                         Units Shipped: <strong>{this.props.InventorySkuRefData[0].UnitsLoaded}</strong>
        //                         <br />
        //                         Cases Shipped: <strong>{this.props.InventorySkuRefData[0].CasesLoaded}</strong>
        //                         <br />
        //                         Pallets Shipped: <strong>{this.props.InventorySkuRefData[0].PalletsLoaded}</strong>
        //                         <br />
        //                     </Panel>
        //                 </Col>

        //             </Row>
        //         </div>



        // }
        // else {
        //     pageHeader = <div>Inventory Management - SKU</div>
        //     skuDetailsDiv = <div></div>
        // }


        let shipmentPoTable;
        let tableDataDiv;
        if (this.props.isInventorySkuWarehouseLocationLoading) {
            shipmentPoTable = <div></div>
            tableDataDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventorySkuWarehouseLocationData) !== 'undefined' && this.props.InventorySkuWarehouseLocationData != null) {
            shipmentPoTable = RTableGeneral(
                "SkuWarehouseLocationID",     //   keyColumn,
                this.props.InventorySkuWarehouseLocationData,     //   tableData,
                InventorySkuWarehouseLocationDataElements,       //   DataElements,
                this.props.isInventorySkuWarehouseLocationLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                false, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                false,      //   boolSelectButton,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Details',     //   link1text,
                this.onClickWarehouseLocationDetails,     //   link1onClick,

                true,       //   link2bool,
                'Edit',     //   link2text,
                this.onclickEditWarehouseLocation,     //   link2onClick,
            )

            tableDataDiv = <Row>
                <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Warehouse_' + currentDateTime}>
                    <ExcelSheet data={this.props.InventorySkuWarehouseLocationData} name="Warehouse">
                        {
                            InventorySkuWarehouseLocationDataElements.map(
                                ({ dBKey, displayName }, idx) => {
                                    return (
                                        <ExcelColumn label={displayName} value={dBKey} />
                                    );
                                }
                            )
                        }
                    </ExcelSheet>
                </ExcelFile>
            </Row>
        }
        else {
            shipmentPoTable = <div></div>
            tableDataDiv = <div></div>
        }







        // let skuRecoveryTimeline;
        // if (this.props.isInventorySkuRecoveryHistoryLoading) {
        //     skuRecoveryTimeline =
        //         <div align="center">
        //             <section className="panel">
        //             </section>
        //             <Loader
        //                 type="TailSpin"
        //                 color="#3396E5"
        //                 height="50"
        //                 width="50"
        //             />
        //         </div>
        // }
        // else if (typeof (this.props.InventorySkuRecoveryHistoryData[0]) !== 'undefined' && this.props.InventorySkuRecoveryHistoryData != null) {
        //     skuRecoveryTimeline =
        //         <div>
        //             <Row >
        //                 <Col md={24} sm={24}>
        //                     <Panel bordered header="SKU Recovery History" style={{ marginBottom: 10 }}>
        //                         <Timeline>
        //                             {this.props.InventorySkuRecoveryHistoryData.map(item => (
        //                                 <Timeline.Item>
        //                                     {item.SkuRecoveryDT}  &nbsp;
        //                                     {item.EmailAddr}
        //                                     <l></l>
        //                                     {/* {item.SKU != null ? <l><br /> SKU:  {item.SKU} </l> : ' '} */}
        //                                     {item.Container != null ? <l><br /> Received From Container:  {item.Container} </l> : ' '}
        //                                     {item.UnitsReceived != null ? <l><br /> Units Received:  {item.UnitsReceived} </l> : ' '}
        //                                     {item.CasesReceived != null ? <l><br /> Cases Received:  {item.CasesReceived} </l> : ' '}
        //                                     {item.PalletsReceived != null ? <l><br /> Pallets Received:  {item.PalletsReceived} </l> : ' '}
        //                                     {/* {item.Oversize != null ? <l><br /> Oversize:  {item.Oversize === true ? "True" : "False"} </l> : ' '} */}
        //                                     {item.SkuDescription != null ? <l><br /> Description:  {item.SkuDescription} </l> : ' '}

        //                                 </Timeline.Item>
        //                             ))}
        //                         </Timeline>

        //                     </Panel>
        //                 </Col>
        //             </Row>

        //         </div>
        // }
        // else {
        //     skuRecoveryTimeline = <div></div>
        // }



        let skuWarehouseLocationHistoryTimeline;
        if (this.props.isInventorySkuWarehouseLocationHistoryLoading) {
            skuWarehouseLocationHistoryTimeline =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventorySkuWarehouseLocationHistoryData[0]) !== 'undefined' && this.props.InventorySkuWarehouseLocationHistoryData != null) {
            skuWarehouseLocationHistoryTimeline =
                <div>
                    <Row >
                        <Col md={24} sm={24}>
                            <Panel bordered header="Warehouse Location History" style={{ marginBottom: 10 }}>
                                <Timeline>
                                    {this.props.InventorySkuWarehouseLocationHistoryData.map(item => (
                                        <Timeline.Item>
                                            {item.CreatedDT}  &nbsp;
                                            {item.EmailAddr}
                                            <l><br />{item.Notes} &nbsp;
                                            </l>
                                            {item.WarehouseLocationName != null ? <l><br /> Warehouse Location:  {item.WarehouseLocationName} </l> : ' '}
                                            {item.PreviousWarehouseLocationName != null ? <l><br /> Previous Warehouse Location:  {item.PreviousWarehouseLocationName} </l> : ' '}
                                            {item.Units != null ? <l><br /> Units:  {item.Units} </l> : ' '}
                                            {item.Cases != null ? <l><br /> Cases:  {item.Cases} </l> : ' '}
                                            {item.Pallets != null ? <l><br /> Pallets:  {item.Pallets} </l> : ' '}
                                            {item.Oversize != null ? <l><br /> Oversize:  {item.Oversize === true ? "True" : "False"} </l> : ' '}


                                        </Timeline.Item>
                                    ))}
                                </Timeline>

                            </Panel>
                        </Col>
                    </Row>

                </div>
        }
        else {
            skuWarehouseLocationHistoryTimeline = <div></div>
        }




        let loadDiv;
        let rTable;
        if (this.props.isInventoryLoadLotLoading) {
            rTable = <div></div>
            loadDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryLoadLotData) !== 'undefined' && this.props.InventoryLoadLotData != null) {
            if (this.props.InventoryLoadLotData.length === 0) {
                rTable = <div></div>
                loadDiv = <div></div>
            }
            else {

                // let rTable;
                rTable = RTableGeneral(
                    'LoadID',     //   keyColumn,
                    this.props.InventoryLoadLotData,     //   tableData,
                    InventoryLoadSkuDetailsDataElements,       //   DataElements,
                    this.props.isInventoryLoadLotLoading,        //   loading,

                    this.props.isMobile,        //   isMobile,
                    this.props.history,     //   history,

                    sortColumn,     //   sortColumn,
                    sortType,       //   sortType,
                    this.handleSortColumn,      //   handleSortColumn,

                    false, // checkColEntry,
                    null,       //   checkedKeys,
                    null,       //   handleCheckAll,
                    null,       //   handleCheck,

                    false,      //   boolSelectButton,
                    null,       //   onClickSelect,
                    null,       //   selectButtonText,
                    null,       //   selectHighlightRowKey,

                    true,       //   link1bool,
                    'Details',     //   link1text,
                    this.onClickLoadDetails,     //   link1onClick,

                    false,       //   link2bool,
                    null,       //   link2text,
                    null  //   link2onClick
                )

                loadDiv = <div>


                    {(this.props.isMobile == true) ? <div></div> :
                        <div>
                            <Row>
                                <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Load_' + currentDateTime}>
                                    <ExcelSheet data={this.props.InventoryLoadLotData} name="Load">

                                        {
                                            InventoryLoadSkuDetailsDataElements.map(
                                                ({ dBKey, displayName }, idx) => {
                                                    return (
                                                        <ExcelColumn label={displayName} value={dBKey} />
                                                    );
                                                }
                                            )
                                        }

                                    </ExcelSheet>
                                </ExcelFile>
                            </Row>
                        </div>
                    }

                </div>
            }
        }
        else {
            rTable = <div></div>
            loadDiv = <div></div>
        }








        // let tableSkuDataDiv;
        // let palletsDiv;
        // let casesDiv;
        // let shipmentSkuTable;
        // if (this.props.isInventorySkuWarehouseLoading) {
        //     shipmentSkuTable = <div></div>
        //     tableSkuDataDiv =
        //         <div align="center">
        //             <section className="panel">
        //             </section>
        //             <Loader
        //                 type="TailSpin"
        //                 color="#3396E5"
        //                 height="50"
        //                 width="50"
        //             />
        //         </div>
        // }
        // else if (typeof (this.props.InventorySkuWarehouseData) !== 'undefined' && this.props.InventorySkuWarehouseData != null) {

        //     const palletBarcodeObject = this.props.InventorySkuWarehouseData.reduce((acc, item) => {
        //         if (!acc[item.PalletBarcode]) {
        //             acc[item.PalletBarcode] = {
        //                 location: item.WarehouseLocationName,
        //                 palletId: item.PalletID
        //             };
        //         }
        //         return acc;
        //     }, {});

        //     const caseBarcodeObject = this.props.InventorySkuWarehouseData.reduce((acc, item) => {
        //         if (!acc[item.CaseBarcode]) {
        //             acc[item.CaseBarcode] = {
        //                 location: item.WarehouseLocationName,
        //                 caseId: item.CaseID
        //             };
        //         }
        //         return acc;
        //     }, {});

        //     const palletBarcodeData = this.getTableData(Object.entries(palletBarcodeObject).map(([pbarcode, { location: plocation, palletId }], index) => ({
        //         id: index,
        //         pbarcode,
        //         plocation,
        //         palletId
        //     })));

        //     const caseBarcodeData = this.getTableData(Object.entries(caseBarcodeObject).map(([cbarcode, { location: clocation, caseId }], index) => ({
        //         id: index,
        //         cbarcode,
        //         clocation,
        //         caseId
        //     })));


        //     palletsDiv = palletBarcodeData && palletBarcodeData.length > 0 ? (
        //         <Table
        //             data={palletBarcodeData}
        //             height={palletBarcodeData.length * 100} // replace 100 with the height of a single row
        //             sortTableColumn={sortTableColumn}
        //             sortTableType={sortTableType}
        //             onSortColumn={this.handleTableSortColumn}
        //         >
        //             <Column width={250} sortable>
        //                 <HeaderCell>Barcode</HeaderCell>
        //                 <Cell dataKey="pbarcode" />
        //             </Column>

        //             <Column width={200} sortable>
        //                 <HeaderCell>Warehouse Location</HeaderCell>
        //                 <Cell dataKey="plocation" />
        //             </Column>

        //             <Column width={200}>
        //                 <HeaderCell></HeaderCell>
        //                 <Cell>
        //                     {rowData => {
        //                         return (
        //                             <a
        //                                 href="#"
        //                                 onClick={(e) => { this.onClickPalletDetails(rowData.palletId) }}
        //                             // onClick={(e) => {
        //                             //     e.preventDefault();
        //                             //     this.onClickPalletDetails(rowData.palletId);
        //                             // }}
        //                             >
        //                                 Details
        //                             </a>
        //                         );
        //                     }}
        //                 </Cell>
        //             </Column>
        //         </Table>
        //     ) : (
        //         <div></div>
        //     );




        //     casesDiv = caseBarcodeData && caseBarcodeData.length > 0 ? (

        //         <Table
        //             data={caseBarcodeData}
        //             height={caseBarcodeData.length * 100} // replace 100 with the height of a single row
        //             sortTableColumn={sortTableColumn}
        //             sortTableType={sortTableType}
        //             onSortColumn={this.handleTableSortColumn}
        //         >
        //             <Column width={250} sortable>
        //                 <HeaderCell>Barcode</HeaderCell>
        //                 <Cell dataKey="cbarcode" />
        //             </Column>

        //             <Column width={200} sortable>
        //                 <HeaderCell>Warehouse Location</HeaderCell>
        //                 <Cell dataKey="clocation" />
        //             </Column>

        //             <Column width={200}>
        //                 <HeaderCell></HeaderCell>
        //                 <Cell>
        //                     {rowData => {
        //                         return (
        //                             <a
        //                                 href="#"
        //                                 onClick={(e) => { this.onClickCaseDetails(rowData.caseId) }}
        //                             // onClick={(e) => {
        //                             //     e.preventDefault();
        //                             //     this.onClickCaseDetails(rowData.caseId);
        //                             // }}
        //                             >
        //                                 Details
        //                             </a>
        //                         );
        //                     }}
        //                 </Cell>
        //             </Column>
        //         </Table>
        //     ) : (
        //         <div></div>
        //     );



        //     const values = queryString.parse(this.props.location.search)
        //     try {
        //         var paramPoStatusID = values.statusid.split(',').map(Number);
        //     }
        //     catch (e) {
        //         var paramPoStatusID = []
        //     }
        //     shipmentSkuTable = RTableGeneral(
        //         'CaseSkuID',     //   keyColumn,
        //         this.props.InventorySkuWarehouseData,     //   tableData,
        //         InventorySkuWarehouseDataElements,       //   DataElements,
        //         this.props.isInventorySkuWarehouseLoading,        //   loading,

        //         this.props.isMobile,        //   isMobile,
        //         this.props.history,     //   history,

        //         sortColumn,     //   sortColumn,
        //         sortType,       //   sortType,
        //         this.handleSortColumn,      //   handleSortColumn,

        //         true, // checkColEntry,
        //         checkedKeys,       //   checkedKeys,
        //         this.handleCheckAll,       //   handleCheckAll,
        //         this.handleCheck,       //   handleCheck,

        //         false,      //   boolSelectButton,
        //         null,       //   onClickSelect,
        //         null,       //   selectButtonText,
        //         null,       //   selectHighlightRowKey,

        //         false,       //   link1bool,
        //         null,     //   link1text,
        //         null,     //   link1onClick,

        //         false,       //   link2bool,
        //         null,       //   link2text,
        //         null  //   link2onClick
        //     )
        //     tableSkuDataDiv = <div>
        //         {(this.props.isMobile == true) ? <div></div> :
        //             <div>
        //                 <Row>
        //                     {/* {paramPoStatusID.includes(1) || paramPoStatusID.includes(2) || paramPoStatusID == null ? <Button style={{ float: 'left' }} onClick={(e) => { this.onClickBuildLoad(e) }}>Build Load Selected</Button> : ''} */}
        //                     <Button style={{ float: 'left' }} onClick={(e) => { this.onClickBuildLoad(e) }}>Build Load Selected</Button>
        //                     <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Warehouse_' + currentDateTime}>
        //                         <ExcelSheet data={this.props.InventorySkuWarehouseData} name="Warehouse">
        //                             {
        //                                 InventorySkuWarehouseDataElements.map(
        //                                     ({ dBKey, displayName }, idx) => {
        //                                         return (
        //                                             <ExcelColumn label={displayName} value={dBKey} />
        //                                         );
        //                                     }
        //                                 )
        //                             }
        //                         </ExcelSheet>
        //                     </ExcelFile>
        //                 </Row>
        //             </div>
        //         }
        //     </div>
        // }
        // else {
        //     shipmentSkuTable = <div></div>
        //     tableSkuDataDiv = <div></div>
        //     palletsDiv = <div></div>
        //     casesDiv = <div></div>
        // }




        let totalUnitsNumber;
        let totalRecoveredNumber;
        let totalPalletNumber;
        if (!this.state.existingDataChangeLoading) {
            try {
                totalUnitsNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.NewUnitsLoaded, 0)
            }
            catch (e) {
                totalUnitsNumber = 0
            }

            try {
                totalRecoveredNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.NewCasesLoaded, 0)
            }
            catch (e) {
                totalRecoveredNumber = 0
            }

            try {
                totalPalletNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.NewPallets, 0)
            }
            catch (e) {
                totalPalletNumber = 0
            }
        }
        else {
            try {
                totalUnitsNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.NewUnitsLoaded, 0)
            }
            catch (e) {
                totalUnitsNumber = 0
            }
            try {
                totalRecoveredNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.NewCasesLoaded, 0)
            }
            catch (e) {
                totalRecoveredNumber = 0
            }
            try {
                totalPalletNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.NewPallets, 0)
            }
            catch (e) {
                totalPalletNumber = 0
            }
        }



        // let buildLoadDrawer;
        // if (this.props.isInventorySkuRefLoading) {
        //     totalRecoveredNumber = 0
        //     totalUnitsNumber = 0
        //     buildLoadDrawer = <div align="center">
        //         <section className="panel">
        //         </section>
        //         <Loader
        //             type="TailSpin"
        //             color="#3396E5"
        //             height="50"
        //             width="50"
        //         />
        //     </div>
        // }
        // else if (this.props.isInventorySkuWarehouseLocationLoading) {
        //     totalRecoveredNumber = 0
        //     totalUnitsNumber = 0
        //     buildLoadDrawer = <div align="center">
        //         <section className="panel">
        //         </section>
        //         <Loader
        //             type="TailSpin"
        //             color="#3396E5"
        //             height="50"
        //             width="50"
        //         />
        //     </div>
        // }
        // else if (typeof (this.state.BuildLoadDict) !== 'undefined' && this.state.BuildLoadDict != null && this.state.BuildLoadDict.length > 0 && this.state.showBuildLoadDrawer) {
        //     // totalRecoveredNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.CasesReceived, 0)

        //     buildLoadDrawer = <PalletBuildLoadDrawer {...this.props} state={this.state} totalUnitsNumber={totalUnitsNumber} totalRecoveredNum={totalRecoveredNumber} totalPalleteNum={totalPalletNumber} onChange={this.onChangeBuildLoadDrawer} onChangeLoad={this.onChangeLoad} onChangeLocation={this.onChangeLocation} onClickRemoveFromAdd={this.onClickRemoveFromAdd} confirm={this.onClickBuildLoadDrawerSave} close={this.closeBuildLoadDrawer} remove={this.onClickRemoveFromBuild} />
        // }



        // let shipmentCaseTable;
        // let tableCaseDataDiv;
        // if (this.props.isInventoryCaseWarehouseLoading) {
        //     shipmentCaseTable = <div></div>
        //     tableCaseDataDiv =
        //         <div align="center">
        //             <section className="panel">
        //             </section>
        //             <Loader
        //                 type="TailSpin"
        //                 color="#3396E5"
        //                 height="50"
        //                 width="50"
        //             />
        //         </div>
        // }
        // else if (typeof (this.props.InventoryCaseWarehouseData) !== 'undefined' && this.props.InventoryCaseWarehouseData != null && this.props.InventoryCaseWarehouseData.length > 0) {
        //     const values = queryString.parse(this.props.location.search)
        //     try {
        //         var paramPoStatusID = values.statusid.split(',').map(Number);
        //     }
        //     catch (e) {
        //         var paramPoStatusID = []
        //     }
        //     shipmentCaseTable = RTableGeneral(
        //         'CaseID',     //   keyColumn,
        //         this.props.InventoryCaseWarehouseData,     //   tableData,
        //         InventoryCaseWarehouseDataElements,       //   DataElements,
        //         this.props.isInventoryCaseWarehouseLoading,        //   loading,

        //         this.props.isMobile,        //   isMobile,
        //         this.props.history,     //   history,

        //         sortColumn,     //   sortColumn,
        //         sortType,       //   sortType,
        //         this.handleSortColumn,      //   handleSortColumn,

        //         false, // checkColEntry,
        //         null,       //   checkedKeys,
        //         null,       //   handleCheckAll,
        //         null,       //   handleCheck,

        //         false,      //   boolSelectButton,
        //         null,       //   onClickSelect,
        //         null,       //   selectButtonText,
        //         null,       //   selectHighlightRowKey,

        //         false,       //   link1bool,
        //         null,     //   link1text,
        //         null,     //   link1onClick,

        //         false,       //   link2bool,
        //         null,       //   link2text,
        //         null  //   link2onClick
        //     )
        //     tableCaseDataDiv = <div>
        //         {(this.props.isMobile == true) ? <div></div> :
        //             <div>
        //                 <Row>

        //                     {paramPoStatusID.includes(1) || paramPoStatusID.includes(2) || paramPoStatusID == null ? <Button style={{ float: 'left' }} onClick={(e) => { this.onClickBuildLoad(e) }}>Build Load Selected</Button> : ''}


        //                     {/* <Button style={{ float: 'left' }} onClick={(e) => { this.onClickMoveCase(e) }} >Move Case</Button> */}
        //                     <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Warehouse_' + currentDateTime}>
        //                         <ExcelSheet data={this.props.InventoryCaseWarehouseData} name="Warehouse">
        //                             {
        //                                 InventoryCaseWarehouseDataElements.map(
        //                                     ({ dBKey, displayName }, idx) => {
        //                                         return (
        //                                             <ExcelColumn label={displayName} value={dBKey} />
        //                                         );
        //                                     }
        //                                 )
        //                             }
        //                         </ExcelSheet>
        //                     </ExcelFile>
        //                 </Row>
        //             </div>
        //         }
        //     </div>
        // }
        // else {
        //     shipmentCaseTable = <div></div>
        //     tableCaseDataDiv = <div></div>
        // }







        let shipmentPalletTable;
        let tablePalletDataDiv;
        let palletDiv;
        if (this.props.isInventoryPalletWarehouseGroupedLoading) {
            palletDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryPalletWarehouseGroupedData) !== 'undefined' && this.props.InventoryPalletWarehouseGroupedData != null) {

            shipmentPalletTable = RTableGeneral(
                'ID',     //   keyColumn,
                this.props.InventoryPalletWarehouseGroupedData,     //   tableData,
                InventoryPalletWarehouseGroupedDataElements,       //   DataElements,
                this.props.isInventoryPalletWarehouseGroupedLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                false, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                false,      //   boolSelectButton,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Pallets',     //   link1text,
                this.onClickPalletDetails,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick
            )
            tablePalletDataDiv = <div>
                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Pallets_' + currentDateTime}>
                                <ExcelSheet data={this.props.InventoryPalletWarehouseGroupedData} name="Pallets">
                                    {
                                        InventoryPalletWarehouseGroupedDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }
            </div>

            if (this.props.InventoryPalletWarehouseGroupedData.length > 0) {
                palletDiv = <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered header="Pallets" style={{ marginBottom: 10 }}>
                            {tablePalletDataDiv}
                            {shipmentPalletTable}
                        </Panel>
                    </Col>
                </Row>

            }
            else {
                palletDiv = <div></div>
            }

        }
        else {
            palletDiv = <div></div>
        }




        let shipmentCaseTable;
        let tableCaseDataDiv;
        let caseDiv;
        if (this.props.isInventoryCaseWarehouseGroupedLoading) {
            caseDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryCaseWarehouseGroupedData) !== 'undefined' && this.props.InventoryCaseWarehouseGroupedData != null) {
            shipmentCaseTable = RTableGeneral(
                'ID',     //   keyColumn,
                this.props.InventoryCaseWarehouseGroupedData,     //   tableData,
                InventoryCaseWarehouseGroupedDataElements,       //   DataElements,
                this.props.isInventoryCaseWarehouseGroupedLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                false, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                false,      //   boolSelectButton,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Cases',     //   link1text,
                this.onClickCaseDetails,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick
            )
            tableCaseDataDiv = <div>
                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Cases_' + currentDateTime}>
                                <ExcelSheet data={this.props.InventoryCaseWarehouseGroupedData} name="Cases">
                                    {
                                        InventoryCaseWarehouseGroupedDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }
            </div>


            if (this.props.InventoryCaseWarehouseGroupedData.length > 0) {
                caseDiv = <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered header="Cases" style={{ marginBottom: 10 }}>
                            {tableCaseDataDiv}
                            {shipmentCaseTable}
                        </Panel>
                    </Col>
                </Row>

            }
            else {
                caseDiv = <div></div>
            }
        }
        else {
            caseDiv = <div></div>
        }


        let lotSkuTable;
        let lotSkuDataDiv;
        let unitDiv;
        if (this.props.isInventoryUnitWarehouseGroupedLoading) {
            unitDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryUnitWarehouseGroupedData) !== 'undefined' && this.props.InventoryUnitWarehouseGroupedData != null) {

            lotSkuTable = RTableGeneral(
                'ID',     //   keyColumn,
                this.props.InventoryUnitWarehouseGroupedData,     //   tableData,
                InventoryUnitWarehouseGroupedDataElements,       //   DataElements,
                this.props.isInventoryUnitWarehouseGroupedLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,
                false, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                false,      //   boolSelectButton,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Units',     //   link1text,
                this.onClickUnitDetails,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick
            )
            lotSkuDataDiv = <div>
                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Units_' + currentDateTime}>
                                <ExcelSheet data={this.props.InventoryUnitWarehouseGroupedData} name="Units">
                                    {
                                        InventoryUnitWarehouseGroupedDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }
            </div>

            if (this.props.InventoryUnitWarehouseGroupedData.length > 0) {
                unitDiv = <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered header="Units" style={{ marginBottom: 10 }}>
                            {lotSkuDataDiv}
                            {lotSkuTable}
                        </Panel>
                    </Col>
                </Row>

            }
            else {
                unitDiv = <div></div>
            }
        }
        else {
            unitDiv = <div></div>
        }








        let skuRecoveryTimeline;
        let skuRecoveryData;
        let skuRecoveryTable;
        if (this.props.isInventoryLotSkuInboundReceiptsGetLoading) {
            skuRecoveryTimeline =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryLotSkuInboundReceiptsData[0]) !== 'undefined' && this.props.InventoryLotSkuInboundReceiptsData != null) {





            skuRecoveryTable = RTableGeneral(
                'RowID',     //   keyColumn,
                this.props.InventoryLotSkuInboundReceiptsData,     //   tableData,
                InventoryLotSkuInboundReceiptsDataElements,       //   DataElements,
                this.props.isInventoryLotSkuInboundReceiptsGetLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,
                false, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                false,      //   boolSelectButton,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                false,       //   link1bool,
                null,     //   link1text,
                null,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick
            )
            skuRecoveryData = <div>
                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'SKUInboundReceipts_' + currentDateTime}>
                                <ExcelSheet data={this.props.InventoryLotSkuInboundReceiptsData} name="SKU Inbound Receipts">
                                    {
                                        InventoryLotSkuInboundReceiptsDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }
            </div>

            if (this.props.InventoryLotSkuInboundReceiptsData.length > 0) {
                skuRecoveryTimeline = <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered header="SKU Inbound Receipts" style={{ marginBottom: 10 }}>
                            {skuRecoveryData}
                            {skuRecoveryTable}
                        </Panel>
                    </Col>
                </Row>

            }
            else {
                skuRecoveryTimeline = <div></div>
            }




            // skuRecoveryTimeline =
            //     <div>
            //         <Row >
            //             <Col md={24} sm={24}>
            //                 <Panel bordered header="SKU Inbound Receipts" style={{ marginBottom: 10 }}>
            //                     <Timeline>
            //                         {this.props.InventoryLotSkuInboundReceiptsData.map(item => (
            //                             <Timeline.Item>
            //                                 {item.ReceiptDT}  &nbsp;
            //                                 {item.EmailAddr}
            //                                 <l></l>
            //                                 {/* {item.SKU != null ? <l><br /> SKU:  {item.SKU} </l> : ' '} */}
            //                                 {item.Container != null ? <l><br /> Container:  {item.Container} </l> : ' '}
            //                                 {item.PalletBarcode != null ? <l><br /> Pallet Barcode:  {item.PalletBarcode} </l> : ' '}
            //                                 {item.CaseBarcode != null ? <l><br /> Case Barcode:  {item.CaseBarcode} </l> : ' '}
            //                                 {item.LotCode != null && item.LotCode != "" ? <l><br /> Lot Code:  {item.LotCode} </l> : ' '}
            //                                 {item.SerialNumber != null && item.SerialNumber != "" ? <l><br /> Serial Number:  {item.SerialNumber} </l> : ' '}
            //                                 {item.Project != null ? <l><br /> Project:  {item.Project} </l> : ' '}
            //                                 {/* {item.Oversize != null ? <l><br /> Oversize:  {item.Oversize === true ? "True" : "False"} </l> : ' '} */}
            //                                 {item.CustomerRef != null ? <l><br /> Customer Ref:  {item.CustomerRef} </l> : ' '}
            //                                 {item.CasesRecovered != null ? <l><br /> Cases Recovered:  {item.CasesRecovered} </l> : ' '}
            //                                 {item.UnitsRecovered != null ? <l><br /> Units Recovered:  {item.UnitsRecovered} </l> : ' '}

            //                             </Timeline.Item>
            //                         ))}
            //                     </Timeline>

            //                 </Panel>
            //             </Col>
            //         </Row>

            //     </div>


        }
        else {
            skuRecoveryTimeline = <div></div>
        }






        return (
            <div>
                <h3>
                    {pageHeader}
                </h3>




                <PanelGroup>
                    {skuRefDetailsDiv}
                </PanelGroup>

                <PanelGroup>
                    {skuDetailsDiv}
                </PanelGroup>


                {/* <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered header="Pallet Locations" style={{ marginBottom: 10 }}>
                            {palletsDiv}
                        </Panel>

                        <Panel bordered header="Case Locations" style={{ marginBottom: 10 }}>
                            {casesDiv}
                        </Panel>
                    </Col>
                </Row> */}


                {palletDiv}
                {caseDiv}
                {unitDiv}

                {/* <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered header="SKU Details" style={{ marginBottom: 10 }}>
                            {tableSkuDataDiv}
                            {shipmentSkuTable}
                        </Panel>
                    </Col>
                </Row> */}

                {/* <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered header="Case Details" style={{ marginBottom: 10 }}>
                            {tableCaseDataDiv}
                            {shipmentCaseTable}
                        </Panel>
                    </Col>
                </Row> */}

                <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered header="Loads" style={{ marginBottom: 10 }}>
                            {loadDiv}
                            {rTable}
                        </Panel>
                    </Col>
                </Row>


                {skuRecoveryTimeline}


                {/* <CaseHistoryDiv
                    isInventoryCaseHistoryLoading={this.props.isInventoryCaseHistoryLoading}
                    InventoryCaseHistoryData={this.props.InventoryCaseHistoryData}
                />


                <CaseSKUsHistoryDiv
                    isInventoryCaseSkusHistoryLoading={this.props.isInventoryCaseSkusHistoryLoading}
                    InventoryCaseSkusHistoryData={this.props.InventoryCaseSkusHistoryData}
                />

                <LoadCaseHistoryDiv
                    isInventoryLoadCasesHistoryLoading={this.props.isInventoryLoadCasesHistoryLoading}
                    InventoryLoadCasesHistoryData={this.props.InventoryLoadCasesHistoryData}
                /> */}


                <EditSkuDetailsDrawer {...this.props} state={this.state} onChangeProject={this.onChangeProject} onChangeNotes={this.onChangeNotes} onChangeCustomerRef={this.onChangeCustomerRef} onChangeSKU={this.onChangeSKU} confirm={this.onClickConfirmEditSku} close={this.closeEditSkuDrawer} unsavedChanges={this.state.unsavedEditSkuValues} />


                {/* {buildLoadDrawer} */}


            </div>
        );

    }
}

export default withRouter(LotSkuDetail);
