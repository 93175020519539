import * as types from '../constants/inventoryActionTypes';
import { appSettings } from '../../app/appSettings'
import { convertNanToZero } from '../../resources/logic/functions';
import pako from 'pako';

const initialState = {

    isInventoryPostLoading: false,

    isInventoryDestinationLoading: false,
    InventoryDestinationData: [],

    isInventoryCustomerRefLoading: false,
    InventoryCustomerRefData: [],

    isInventoryProjectLoading: false,
    InventoryProjectData: [],

    isInventoryLotSkuLoading: false,
    InventoryLotSkuData: [],

    isInventorySkuLoading: false,
    InventorySkuData: [],

    InventorySkuPostSuccessful: false,
    SkuID: null,

    isInventoryContainerLoading: false,
    InventoryContainerData: [],

    isInventoryLotContainerLoading: false,
    InventoryLotContainerData: [],

    InventoryContainerPostSuccessful: false,
    NewContainerId: null,

    InventoryContainerPostSuccessful2: false,
    NewContainerId2: null,

    InventoryContainerStatusPostSuccessful: false,

    isInventoryContainerHistoryLoading: false,
    InventoryContainerHistoryData: [],

    isInventoryContainerSkuLoading: false,
    InventoryContainerSkuData: [],

    InventoryContainerSkuPostSuccessful: false,

    isInventorySkuRecoveryLoading: false,
    InventorySkuRecoveryData: [],

    isInventorySkuRecoveryHistoryLoading: false,
    InventorySkuRecoveryHistoryData: [],

    InventorySkuRecoveryPostSuccessful: false,

    isInventorySkuWarehouseLocationLoading: false,
    InventorySkuWarehouseLocationData: [],
    SkuLocationDataGrouped: [],

    InventorySkuWarehouseLocationPostSuccessful: false,

    isInventorySkuWarehouseLocationHistoryLoading: false,
    InventorySkuWarehouseLocationHistoryData: [],

    isInventoryWarehouseLocationLoading: false,
    InventoryWarehouseLocationData: [],

    isInventoryLoadLoading: false,
    InventoryLoadData: [],

    InventoryLoadPostSuccessful: false,

    InventoryLoadRemoveSkuPostSuccessful: false,

    isInventoryLoadSkuLoading: false,
    InventoryLoadSkuData: [],

    isInventoryLoadSkuHistoryLoading: false,
    InventoryLoadSkuHistoryData: [],

    isInventoryLoadSkuLocationHistoryLoading: false,
    InventoryLoadSkuLocationHistoryData: [],

    isInventoryOutboundLoading: false,
    InventoryOutboundData: [],


    isInventoryReportingInboundLoading: false,
    InventoryReportingInboundData: [],

    isInventoryReportingStorageLoading: false,
    InventoryReportingStorageData: [],

    InventoryRecoveryPostLoading: false,
    InventoryRecoveryPostSuccessful: false,
    InventoryRecoveryPalletBarcodes: null,
    InventoryRecoveryCaseBarcodes: null,
    InventoryRecoveryPalletContent: null,
    InventoryRecoveryCaseSku: null,
    InventoryRecoveryCaseInfo: null,


    isInventorySkuWarehouseGroupedLoading: false,
    InventorySkuWarehouseGroupedData: [],

    isInventorySkuWarehouseLoading: false,
    InventorySkuWarehouseData: [],

    isInventoryUnitWarehouseGroupedLoading: false,
    InventoryUnitWarehouseGroupedData: [],

    isInventoryCaseWarehouseGroupedLoading: false,
    InventoryCaseWarehouseGroupedData: [],

    isInventoryCaseWarehouseLoading: false,
    InventoryCaseWarehouseData: [],

    InventoryCaseWarehouseLocationPostSuccessful: false,

    isInventoryPalletWarehouseGroupedLoading: false,
    InventoryPalletWarehouseGroupedData: [],

    isInventoryPalletWarehouseLoading: false,
    InventoryPalletWarehouseData: [],

    isInventoryPalletWarehouseLoading2: false,
    InventoryPalletWarehouseData2: [],

    InventoryPalletWarehouseLocationPostSuccessful: false,

    InventoryLoadPalletCaseSkuPostLoading: false,
    InventoryLoadPalletCaseSkuPostSuccessful: false,
    InventoryLoadPalletCaseSkuPostNewLoadID: null,

    isInventoryLoadPalletCaseUnitLoading: false,
    InventoryLoadPalletCaseUnitData: [],

    isInventoryLoadLotLoading: false,
    InventoryLoadLotData: [],

    isInventoryPalletContentsLoading: false,
    InventoryPalletContentsData: [],

    InventoryLoadBarcodeScanPostSuccessful: false,

    InventoryPalletCreateNewPostSuccessful: false,
    InventoryNewPalletBarcode: null,

    isInventorySkuRefLoading: false,
    InventorySkuRefData: [],




    isInventoryCaseHistoryLoading: false,
    InventoryCaseHistoryData: [],

    isInventoryCaseSkusHistoryLoading: false,
    InventoryCaseSkusHistoryData: [],

    isInventoryLoadCasesHistoryLoading: false,
    InventoryLoadCasesHistoryData: [],

    isInventoryLoadPalletsHistoryLoading: false,
    InventoryLoadPalletsHistoryData: [],

    isInventoryLoadSKUsHistoryLoading: false,
    InventoryLoadSKUsHistoryData: [],

    isInventoryPalletWarehouseLocationHistoryLoading: false,
    InventoryPalletWarehouseLocationHistoryData: [],


    InventoryLoadRemovePalletCaseSkuPostSuccessful: false,

    isInventoryLotBarcodeScanValidateLoading: false,
    InventoryLotBarcodeScanValidateData: [],

    isInventoryLotOutboundLoading: false,
    InventoryLotOutboundData: [],

    isInventoryLotReportingInboundLoading: false,
    InventoryLotReportingInboundData: [],

    isInventoryLotReportingStorageLoading: false,
    InventoryLotReportingStorageData: [],

    isInventoryLotHistoryTypeLoading: false,
    InventoryLotHistoryTypeData: [],

    InventoryDeleteContainerPalletCaseUnitPostSuccessful: false,
    isInventoryDeleteContainerPalletCaseUnitLoading: false,

    InventoryLotCaseRefEditPostSuccessful: false,
    isInventoryLotCaseRefEditLoading: false,

    InventoryLotUnitCaseSkuRefEditPostSuccessful: false,
    isInventoryLotUnitCaseSkuRefEditLoading: false,

    InventoryLotPalletEditPostSuccessful: false,
    isInventoryLotPalletRefEditLoading: false,


    InventoryLotContainerPostSuccessful: false,
    NewLotContainerId: null,



    isInventoryLotOrderOutboundLoading: false,
    InventoryLotOrderOutboundPostSuccessful: false,
    NewOutboundOrderId: null,

    isInventoryLotOrderOutboundSkuLoading: false,
    InventoryLotOrderOutboundSkuPostSuccessful: false,

    isInventoryLotOrderOutboundGetLoading: false,
    InventoryLotOrderOutboundData: [],


    isInventoryLotOrderOutboundSkuGetLoading: false,
    InventoryLotOrderOutboundSkuData: [],


    isInventoryLotOrderOutboundSkuEachLocationsGetLoading: false,
    InventoryLotOrderOutboundSkuEachLocationsData: [],

    isInventoryLotOrderOutboundSkuCaseLocationsGetLoading: false,
    InventoryLotOrderOutboundSkuCaseLocationsData: [],

    isInventoryLotPalletSkuUnitsGetLoading: false,
    InventoryLotPalletSkuUnitsData: [],

    isInventoryLotPalletSkuCasesGetLoading: false,
    InventoryLotPalletSkuCasesData: [],

    isInventoryLotCaseSkuUnitsGetLoading: false,
    InventoryLotCaseSkuUnitsData: [],


    isInventoryLotOrderOutboundSkuPalletLocationsGetLoading: false,
    InventoryLotOrderOutboundSkuPalletLocationsData: [],

    isInventoryLotOrderOutboundHistoryGetLoading: false,
    InventoryLotOrderOutboundHistoryData: [],



    isInventoryLotLoadFileUploadLoading: false,
    InventoryLotLoadFileUploadData: [],
    InventoryLotLoadFileUploadPostSuccessful: false,

    isInventoryLotSkuInboundReceiptsGetLoading: false,
    InventoryLotSkuInboundReceiptsData: [],


};

//Compressed Data Helper Functions
function decodeAndDecompress(data) {
    try {
        var binaryString = atob(data);
        var len = binaryString.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        var decompressedData = pako.inflate(bytes, { to: 'string' });
        return JSON.parse(decompressedData);
    } catch (error) {
        console.error('Error decoding base64 data:', error);
        return [];
    }
}
function handleAction(state, action, loadingKey, dataKey, getActionType, completeActionType) {
    switch (action.type) {
        case getActionType:
            return { ...state, [loadingKey]: true };
        case completeActionType:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            try {
                var compressedBody = JSON.parse(action.payload.body);
                var newData = decodeAndDecompress(compressedBody.compressed_data);
                return { ...state, [loadingKey]: false, [dataKey]: newData };
            } catch (error) {
                console.error('Error decoding base64 data:', error);
                return { ...state, [loadingKey]: false, [dataKey]: [] };
            }
        default:
            return state;
    }
}


function handleInventoryPost1(state, action, successStatus) {
    if (!checkStatusCode(action.payload)) {
        return actionOnError()
    }
    var newData = JSON.parse(action.payload.body);
    var msgCode;
    try {
        msgCode = newData.data[0].MsgCode
    }
    catch (e) {
        msgCode = 200
    }
    if (msgCode === 200) {
        // window.alert('Save Successful')
        return Object.assign({}, state, {
            [successStatus]: true,
            isInventoryPostLoading: false
        });
    }
    else {
        try {
            window.alert(newData.data[0].MSG)
        }
        catch (e) {
            window.alert('Error, please try again.')
        }
        return Object.assign({}, state, {
            [successStatus]: false,
            isInventoryPostLoading: false
        });
    }
}


function handlePostAction(state, action, loadingKey, successKey) {
    if (!checkStatusCode(action.payload)) {
        return actionOnError()
    }
    var newData = JSON.parse(action.payload.body);
    var msgCode;
    try {
        msgCode = newData.data[0].MsgCode
    }
    catch (e) {
        msgCode = 200
    }
    if (msgCode === 200) {
        return Object.assign({}, state, {
            [successKey]: true,
            [loadingKey]: false
        });
    }
    else {
        try {
            window.alert(newData.data[0].MSG)
        }
        catch (e) {
            window.alert('Error, please try again.')
        }
        return Object.assign({}, state, {
            [successKey]: false,
            [loadingKey]: false
        });
    }
}


function handleInventoryGet(state, action, isLoading, loadingPropName, dataPropName, data = null) {
    return Object.assign({}, state, {
        [loadingPropName]: isLoading,
        [dataPropName]: data
    });
}

function checkStatusCode(payload) {
    var errorType = 'None'
    try {
        errorType = payload['errorType']
    }
    catch (e) {
    }
    if (typeof (errorType) !== 'undefined') {
        return false
    }
    else {
        var res = payload['statusCode']
        try {
            if (res === "200" || res == 200 || res == 600 || res === "600") { return true }
            else { return false }
        }
        catch (e) {
            return false
        }
    }
}

function actionOnError(proc) {
    // sessionStorage.clear();
    // window.location.reload();
    // // window.alert('Session expired, please log in again.')
    // window.alert('Error please try again.')


    return initialState


}




export const InventoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.RESET_REDUX_STATE:
            state = initialState;
            return state


        case types.ON_ERROR:
            var errorstate = actionOnError(state)
            return errorstate;









        /* ################################### INVENTORY ACTIONS ################################### */


        case types.INVENTORY_DESTINATION_GET:
            return Object.assign({}, state, {
                isInventoryDestinationLoading: true,
            });
        case types.INVENTORY_DESTINATION_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            return Object.assign({}, state, {
                isInventoryDestinationLoading: false,
                InventoryDestinationData: newData
            });


        case types.INVENTORY_CUSTOMERREF_GET:
            return Object.assign({}, state, {
                isInventoryCustomerRefLoading: true,
            });
        case types.INVENTORY_CUSTOMERREF_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            return Object.assign({}, state, {
                isInventoryCustomerRefLoading: false,
                InventoryCustomerRefData: newData
            });

        case types.INVENTORY_PROJECT_GET:
            return Object.assign({}, state, {
                isInventoryProjectLoading: true,
            });
        case types.INVENTORY_PROJECT_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            return Object.assign({}, state, {
                isInventoryProjectLoading: false,
                InventoryProjectData: newData
            });


        case types.INVENTORY_LOT_SKU_GET:
        case types.INVENTORY_LOT_SKU_COMPLETE:
            return handleAction(state, action,
                'isInventoryLotSkuLoading',
                'InventoryLotSkuData',
                types.INVENTORY_LOT_SKU_GET,
                types.INVENTORY_LOT_SKU_COMPLETE);

        case types.INVENTORY_SKU_GET:
        case types.INVENTORY_SKU_COMPLETE:
            return handleAction(state, action,
                'isInventorySkuLoading',
                'InventorySkuData',
                types.INVENTORY_SKU_GET,
                types.INVENTORY_SKU_COMPLETE);

        case types.INVENTORY_POST_SKU_GET:
            return Object.assign({}, state, {
                InventorySkuPostSuccessful: false,
                SkuID: null,
                isInventoryPostLoading: true
            });
        case types.INVENTORY_POST_SKU_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            var msgCode;
            var newID;
            try {
                msgCode = newData[0].MsgCode
                newID = newData[0].SkuID
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    InventorySkuPostSuccessful: true,
                    SkuID: newID,
                    isInventoryPostLoading: false
                });
            }
            else {
                try {
                    window.alert(newData[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    InventorySkuPostSuccessful: false,
                    SkuID: null,
                    isInventoryPostLoading: false
                });
            }


        case types.INVENTORY_LOT_CONTAINER_GET:
        case types.INVENTORY_LOT_CONTAINER_COMPLETE:
            return handleAction(state, action,
                'isInventoryLotContainerLoading',
                'InventoryLotContainerData',
                types.INVENTORY_LOT_CONTAINER_GET,
                types.INVENTORY_LOT_CONTAINER_COMPLETE);

        case types.INVENTORY_CONTAINER_GET:
        case types.INVENTORY_CONTAINER_COMPLETE:
            return handleAction(state, action,
                'isInventoryContainerLoading',
                'InventoryContainerData',
                types.INVENTORY_CONTAINER_GET,
                types.INVENTORY_CONTAINER_COMPLETE);


        case types.INVENTORY_POST_CONTAINER_GET:
            return Object.assign({}, state, {
                InventoryContainerPostSuccessful: false,
                NewContainerId: null,
                isInventoryPostLoading: true
            });
        case types.INVENTORY_POST_CONTAINER_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            var msgCode;
            var newID;
            try {
                msgCode = newData[0].MsgCode
                newID = newData[0].NewContainerId
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    InventoryContainerPostSuccessful: true,
                    NewContainerId: newID,
                    isInventoryPostLoading: false
                });
            }
            else {
                try {
                    window.alert(newData[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    InventoryContainerPostSuccessful: false,
                    NewContainerId: null,
                    isInventoryPostLoading: false
                });
            }


        case types.INVENTORY_POST_CONTAINER_GET2:
            return Object.assign({}, state, {
                InventoryContainerPostSuccessful2: false,
                NewContainerId: null,
                isInventoryPostLoading: true
            });
        case types.INVENTORY_POST_CONTAINER_COMPLETE2:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            var msgCode;
            var newID;
            try {
                msgCode = newData[0].MsgCode
                newID = newData[0].NewContainerId
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    InventoryContainerPostSuccessful2: true,
                    NewContainerId2: newID,
                    isInventoryPostLoading: false
                });
            }
            else {
                try {
                    window.alert(newData[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    InventoryContainerPostSuccessful2: false,
                    NewContainerId2: null,
                    isInventoryPostLoading: false
                });
            }



        case types.INVENTORY_POST_CONTAINER_STATUS_GET:
            return Object.assign({}, state, {
                InventoryContainerStatusPostSuccessful: false,
                isInventoryPostLoading: true
            });
        case types.INVENTORY_POST_CONTAINER_STATUS_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            var msgCode;
            try {
                msgCode = newData[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    InventoryContainerStatusPostSuccessful: true,
                    isInventoryPostLoading: false

                });
            }
            else {
                try {
                    window.alert(newData[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    InventoryContainerStatusPostSuccessful: false,
                    isInventoryPostLoading: false
                });
            }




        case types.INVENTORY_CONTAINER_HISTORY_GET:
        case types.INVENTORY_CONTAINER_HISTORY_COMPLETE:
            return handleAction(state, action,
                'isInventoryContainerHistoryLoading',
                'InventoryContainerHistoryData',
                types.INVENTORY_CONTAINER_HISTORY_GET,
                types.INVENTORY_CONTAINER_HISTORY_COMPLETE);


        case types.INVENTORY_CONTAINER_SKU_GET:
        case types.INVENTORY_CONTAINER_SKU_COMPLETE:
            return handleAction(state, action,
                'isInventoryContainerSkuLoading',
                'InventoryContainerSkuData',
                types.INVENTORY_CONTAINER_SKU_GET,
                types.INVENTORY_CONTAINER_SKU_COMPLETE);


        case types.INVENTORY_POST_CONTAINER_SKU_GET:
            return Object.assign({}, state, {
                InventoryContainerSkuPostSuccessful: false,
                isInventoryPostLoading: true
            });
        case types.INVENTORY_POST_CONTAINER_SKU_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            var msgCode;
            try {
                msgCode = newData[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    InventoryContainerSkuPostSuccessful: true,
                    isInventoryPostLoading: false

                });
            }
            else {
                try {
                    window.alert(newData[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    InventoryContainerSkuPostSuccessful: false,
                    isInventoryPostLoading: false
                });
            }





        case types.INVENTORY_SKU_RECOVERY_GET:
        case types.INVENTORY_SKU_RECOVERY_COMPLETE:
            return handleAction(state, action,
                'isInventorySkuRecoveryLoading',
                'InventorySkuRecoveryData',
                types.INVENTORY_SKU_RECOVERY_GET,
                types.INVENTORY_SKU_RECOVERY_COMPLETE);

        case types.INVENTORY_SKU_RECOVERY_HISTORY_GET:
        case types.INVENTORY_SKU_RECOVERY_HISTORY_COMPLETE:
            return handleAction(state, action,
                'isInventorySkuRecoveryHistoryLoading',
                'InventorySkuRecoveryHistoryData',
                types.INVENTORY_SKU_RECOVERY_HISTORY_GET,
                types.INVENTORY_SKU_RECOVERY_HISTORY_COMPLETE);


        case types.INVENTORY_POST_SKU_RECOVERY_GET:
            return Object.assign({}, state, {
                InventorySkuRecoveryPostSuccessful: false,
                isInventoryPostLoading: true
            });
        case types.INVENTORY_POST_SKU_RECOVERY_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            var msgCode;
            try {
                msgCode = newData[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    InventorySkuRecoveryPostSuccessful: true,
                    isInventoryPostLoading: false

                });
            }
            else {
                try {
                    window.alert(newData[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    InventorySkuRecoveryPostSuccessful: false,
                    isInventoryPostLoading: false
                });
            }


        case types.INVENTORY_SKU_WAREHOUSE_LOC_GET:
            return Object.assign({}, state, {
                isInventorySkuWarehouseLocationLoading: true,
            });
        case types.INVENTORY_SKU_WAREHOUSE_LOC_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);

            var newLocationDict = newData.reduce((h, SkuItem) => Object.assign(h, { [SkuItem.SkuID]: (h[SkuItem.SkuID] || []).concat({ SkuWarehouseLocationID: SkuItem.SkuWarehouseLocationID, WarehouseLocationID: SkuItem.WarehouseLocationID, WarehouseLocationName: SkuItem.WarehouseLocationName, Units: SkuItem.Units, Cases: SkuItem.Cases, Pallets: SkuItem.Pallets, Oversize: SkuItem.Oversize }) }), {})

            // let arr = [
            //     {name:'g1', variant:'v1', count:1},
            //     {name:'g2', variant:'v1', count:2}, 
            //     {name:'g1', variant:'v1', count:2}, 
            //     {name:'g2', variant:'v1', count:2}
            //   ];   

            //   const result = arr.reduce((a, {name, variant, count}) => {
            //     a[name] = a[name] || {name, variant, count: 0};
            //     a[name].count += count;
            //     return a;
            //   }, {})



            // const newPoToPost = currentListPo.map(({ SkuID, NewCasesReceived, NewPallets }) => ({ SkuID, NewCasesReceived, NewPallets }));
            // var poString = JSON.stringify(newPoToPost)


            //   const combineresult = newLocationDict.map((a, {WarehouseLocationID, Units, Cases, Pallets}) => {


            //     a[WarehouseLocationID] = a[WarehouseLocationID] || {WarehouseLocationID, Units: 0, Cases: 0, Pallets: 0};


            //     a[WarehouseLocationID].Units += convertNanToZero(Units);
            //     a[WarehouseLocationID].Cases += convertNanToZero(Cases);
            //     a[WarehouseLocationID].Pallets += convertNanToZero(Pallets);


            //     return a;
            //   }, {})




            return Object.assign({}, state, {
                isInventorySkuWarehouseLocationLoading: false,
                InventorySkuWarehouseLocationData: newData,
                SkuLocationDataGrouped: newLocationDict
            });

        case types.INVENTORY_POST_SKU_WAREHOUSE_LOCATION_GET:
            return Object.assign({}, state, {
                InventorySkuWarehouseLocationPostSuccessful: false,
                isInventoryPostLoading: true
            });
        case types.INVENTORY_POST_SKU_WAREHOUSE_LOCATION_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            var msgCode;
            try {
                msgCode = newData[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    InventorySkuWarehouseLocationPostSuccessful: true,
                    isInventoryPostLoading: false

                });
            }
            else {
                try {
                    window.alert(newData[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    InventorySkuWarehouseLocationPostSuccessful: false,
                    isInventoryPostLoading: false
                });
            }


        case types.INVENTORY_SKU_WAREHOUSE_LOCATION_HISTORY_GET:
        case types.INVENTORY_SKU_WAREHOUSE_LOCATION_HISTORY_COMPLETE:
            return handleAction(state, action,
                'isInventorySkuWarehouseLocationHistoryLoading',
                'InventorySkuWarehouseLocationHistoryData',
                types.INVENTORY_SKU_WAREHOUSE_LOCATION_HISTORY_GET,
                types.INVENTORY_SKU_WAREHOUSE_LOCATION_HISTORY_COMPLETE);


        case types.INVENTORY_WAREHOUSE_LOCATION_GET:
        case types.INVENTORY_WAREHOUSE_LOCATION_COMPLETE:
            return handleAction(state, action,
                'isInventoryWarehouseLocationLoading',
                'InventoryWarehouseLocationData',
                types.INVENTORY_WAREHOUSE_LOCATION_GET,
                types.INVENTORY_WAREHOUSE_LOCATION_COMPLETE);


        case types.INVENTORY_LOAD_GET:
        case types.INVENTORY_LOAD_COMPLETE:
            return handleAction(state, action,
                'isInventoryLoadLoading',
                'InventoryLoadData',
                types.INVENTORY_LOAD_GET,
                types.INVENTORY_LOAD_COMPLETE);



        case types.INVENTORY_POST_LOAD_GET:
            return Object.assign({}, state, {
                InventoryLoadPostSuccessful: false,
                isInventoryPostLoading: true
            });
        case types.INVENTORY_POST_LOAD_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            var msgCode;
            try {
                msgCode = newData[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    InventoryLoadPostSuccessful: true,
                    isInventoryPostLoading: false

                });
            }
            else {
                try {
                    window.alert(newData[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    InventoryLoadPostSuccessful: false,
                    isInventoryPostLoading: false
                });
            }


        case types.INVENTORY_POST_LOAD_REMOVE_SKU_GET:
            return Object.assign({}, state, {
                InventoryLoadRemoveSkuPostSuccessful: false,
                isInventoryPostLoading: true
            });
        case types.INVENTORY_POST_LOAD_REMOVE_SKU_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            var msgCode;
            try {
                msgCode = newData[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    InventoryLoadRemoveSkuPostSuccessful: true,
                    isInventoryPostLoading: false

                });
            }
            else {
                try {
                    window.alert(newData[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    InventoryLoadRemoveSkuPostSuccessful: false,
                    isInventoryPostLoading: false
                });
            }




        case types.INVENTORY_LOAD_SKU_GET:
        case types.INVENTORY_LOAD_SKU_COMPLETE:
            return handleAction(state, action,
                'isInventoryLoadSkuLoading',
                'InventoryLoadSkuData',
                types.INVENTORY_LOAD_SKU_GET,
                types.INVENTORY_LOAD_SKU_COMPLETE);

        case types.INVENTORY_LOAD_SKU_HISTORY_GET:
        case types.INVENTORY_LOAD_SKU_HISTORY_COMPLETE:
            return handleAction(state, action,
                'isInventoryLoadSkuHistoryLoading',
                'InventoryLoadSkuHistoryData',
                types.INVENTORY_LOAD_SKU_HISTORY_GET,
                types.INVENTORY_LOAD_SKU_HISTORY_COMPLETE);


        case types.INVENTORY_LOAD_SKU_LOCATION_HISTORY_GET:
        case types.INVENTORY_LOAD_SKU_LOCATION_HISTORY_COMPLETE:
            return handleAction(state, action,
                'isInventoryLoadSkuLocationHistoryLoading',
                'InventoryLoadSkuLocationHistoryData',
                types.INVENTORY_LOAD_SKU_LOCATION_HISTORY_GET,
                types.INVENTORY_LOAD_SKU_LOCATION_HISTORY_COMPLETE);

        case types.INVENTORY_OUTBOUND_GET:
        case types.INVENTORY_OUTBOUND_COMPLETE:
            return handleAction(state, action,
                'isInventoryOutboundLoading',
                'InventoryOutboundData',
                types.INVENTORY_OUTBOUND_GET,
                types.INVENTORY_OUTBOUND_COMPLETE);

        case types.INVENTORY_REPORTING_INBOUND_GET:
        case types.INVENTORY_REPORTING_INBOUND_COMPLETE:
            return handleAction(state, action,
                'isInventoryReportingInboundLoading',
                'InventoryReportingInboundData',
                types.INVENTORY_REPORTING_INBOUND_GET,
                types.INVENTORY_REPORTING_INBOUND_COMPLETE);

        case types.INVENTORY_REPORTING_STORAGE_GET:
        case types.INVENTORY_REPORTING_STORAGE_COMPLETE:
            return handleAction(state, action,
                'isInventoryReportingStorageLoading',
                'InventoryReportingStorageData',
                types.INVENTORY_REPORTING_STORAGE_GET,
                types.INVENTORY_REPORTING_STORAGE_COMPLETE);


        case types.INVENTORY_POST_RECOVERY_GET:
            return Object.assign({}, state, {
                InventoryRecoveryPostSuccessful: false,
                InventoryRecoveryPostLoading: true
            });
        case types.INVENTORY_POST_RECOVERY_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            var msgCode;
            var palletBarcodes;
            var caseBarcodes;
            var palletContent;
            var caseSku;
            var caseInfo;
            try {
                msgCode = newData[0].MsgCode

                palletBarcodes = newData[0].PalletBarcodes
                caseBarcodes = newData[0].CaseBarcodes
                palletContent = JSON.parse(newData[0].PalletContent)
                caseSku = JSON.parse(newData[0].CaseSku)

                caseInfo = JSON.parse(newData[0].CaseInfo)


            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    InventoryRecoveryPostLoading: false,
                    InventoryRecoveryPostSuccessful: true,
                    InventoryRecoveryPalletBarcodes: palletBarcodes,
                    InventoryRecoveryCaseBarcodes: caseBarcodes,

                    InventoryRecoveryPalletContent: palletContent,
                    InventoryRecoveryCaseSku: caseSku,
                    InventoryRecoveryCaseInfo: caseInfo,

                });
            }
            else {
                try {
                    window.alert(newData[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    InventoryRecoveryPostLoading: false,
                    InventoryRecoveryPostSuccessful: false,
                    InventoryRecoveryPalletBarcodes: null,
                    InventoryRecoveryCaseBarcodes: null,
                    InventoryRecoveryPalletContent: null,
                    InventoryRecoveryCaseSku: null,
                    InventoryRecoveryCaseInfo: null,
                });
            }




        case types.INVENTORY_SKU_WAREHOUSE_LOCATION_GROUPED_GET:
        case types.INVENTORY_SKU_WAREHOUSE_LOCATION_GROUPED_COMPLETE:
            return handleAction(state, action,
                'isInventorySkuWarehouseGroupedLoading',
                'InventorySkuWarehouseGroupedData',
                types.INVENTORY_SKU_WAREHOUSE_LOCATION_GROUPED_GET,
                types.INVENTORY_SKU_WAREHOUSE_LOCATION_GROUPED_COMPLETE);


        case types.INVENTORY_SKU_WAREHOUSE_LOCATION_GET:
        case types.INVENTORY_SKU_WAREHOUSE_LOCATION_COMPLETE:
            return handleAction(state, action,
                'isInventorySkuWarehouseLoading',
                'InventorySkuWarehouseData',
                types.INVENTORY_SKU_WAREHOUSE_LOCATION_GET,
                types.INVENTORY_SKU_WAREHOUSE_LOCATION_COMPLETE);


        case types.INVENTORY_UNIT_WAREHOUSE_LOCATION_GROUPED_GET:
        case types.INVENTORY_UNIT_WAREHOUSE_LOCATION_GROUPED_COMPLETE:
            return handleAction(state, action,
                'isInventoryUnitWarehouseGroupedLoading',
                'InventoryUnitWarehouseGroupedData',
                types.INVENTORY_UNIT_WAREHOUSE_LOCATION_GROUPED_GET,
                types.INVENTORY_UNIT_WAREHOUSE_LOCATION_GROUPED_COMPLETE);


        case types.INVENTORY_CASE_WAREHOUSE_LOCATION_GROUPED_GET:
        case types.INVENTORY_CASE_WAREHOUSE_LOCATION_GROUPED_COMPLETE:
            return handleAction(state, action,
                'isInventoryCaseWarehouseGroupedLoading',
                'InventoryCaseWarehouseGroupedData',
                types.INVENTORY_CASE_WAREHOUSE_LOCATION_GROUPED_GET,
                types.INVENTORY_CASE_WAREHOUSE_LOCATION_GROUPED_COMPLETE);


        case types.INVENTORY_POST_CASE_WAREHOUSE_LOCATION_GET:
            return Object.assign({}, state, {
                InventoryCaseWarehouseLocationPostSuccessful: false,
                isInventoryPostLoading: true
            });
        case types.INVENTORY_POST_CASE_WAREHOUSE_LOCATION_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            var msgCode;
            try {
                msgCode = newData[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    InventoryCaseWarehouseLocationPostSuccessful: true,
                    isInventoryPostLoading: false

                });
            }
            else {
                try {
                    window.alert(newData[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    InventoryCaseWarehouseLocationPostSuccessful: false,
                    isInventoryPostLoading: false
                });
            }


        case types.INVENTORY_PALLET_WAREHOUSE_LOCATION_GROUPED_GET:
        case types.INVENTORY_PALLET_WAREHOUSE_LOCATION_GROUPED_COMPLETE:
            return handleAction(state, action,
                'isInventoryPalletWarehouseGroupedLoading',
                'InventoryPalletWarehouseGroupedData',
                types.INVENTORY_PALLET_WAREHOUSE_LOCATION_GROUPED_GET,
                types.INVENTORY_PALLET_WAREHOUSE_LOCATION_GROUPED_COMPLETE);


        case types.INVENTORY_PALLET_WAREHOUSE_LOCATION_GET2:
        case types.INVENTORY_PALLET_WAREHOUSE_LOCATION_COMPLETE2:
            return handleAction(state, action,
                'isInventoryPalletWarehouseLoading2',
                'InventoryPalletWarehouseData2',
                types.INVENTORY_PALLET_WAREHOUSE_LOCATION_GET2,
                types.INVENTORY_PALLET_WAREHOUSE_LOCATION_COMPLETE2);

        case types.INVENTORY_POST_PALLET_WAREHOUSE_LOCATION_GET:
            return Object.assign({}, state, {
                InventoryPalletWarehouseLocationPostSuccessful: false,
                isInventoryPostLoading: true
            });
        case types.INVENTORY_POST_PALLET_WAREHOUSE_LOCATION_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            var msgCode;
            try {
                msgCode = newData[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    InventoryPalletWarehouseLocationPostSuccessful: true,
                    isInventoryPostLoading: false

                });
            }
            else {
                try {
                    window.alert(newData[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    InventoryPalletWarehouseLocationPostSuccessful: false,
                    isInventoryPostLoading: false
                });
            }


        case types.INVENTORY_POST_LOAD_PALLET_CASE_SKU_GET:
            return Object.assign({}, state, {
                InventoryLoadPalletCaseSkuPostSuccessful: false,
                InventoryLoadPalletCaseSkuPostLoading: true,
                InventoryLoadPalletCaseSkuPostNewLoadID: null,
            });
        case types.INVENTORY_POST_LOAD_PALLET_CASE_SKU_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            var msgCode;
            try {
                msgCode = newData[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            var newLoadID;
            try {
                newLoadID = newData[0].LoadID
            }
            catch (e) {
                newLoadID = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    InventoryLoadPalletCaseSkuPostLoading: false,
                    InventoryLoadPalletCaseSkuPostSuccessful: true,
                    InventoryLoadPalletCaseSkuPostNewLoadID: newLoadID

                });
            }
            else {
                try {
                    window.alert(newData[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    InventoryLoadPalletCaseSkuPostSuccessful: false,
                    InventoryLoadPalletCaseSkuPostNewLoadID: null
                });
            }


        case types.INVENTORY_LOAD_PALLET_CASE_UNIT_GET:
        case types.INVENTORY_LOAD_PALLET_CASE_UNIT_COMPLETE:
            return handleAction(state, action,
                'isInventoryLoadPalletCaseUnitLoading',
                'InventoryLoadPalletCaseUnitData',
                types.INVENTORY_LOAD_PALLET_CASE_UNIT_GET,
                types.INVENTORY_LOAD_PALLET_CASE_UNIT_COMPLETE);

        case types.INVENTORY_LOAD_LOT_GET:
        case types.INVENTORY_LOAD_LOT_COMPLETE:
            return handleAction(state, action,
                'isInventoryLoadLotLoading',
                'InventoryLoadLotData',
                types.INVENTORY_LOAD_LOT_GET, // Corrected here
                types.INVENTORY_LOAD_LOT_COMPLETE); // Corrected here



        case types.INVENTORY_PALLET_CONTENTS_GET:
        case types.INVENTORY_PALLET_CONTENTS_COMPLETE:
            return handleAction(state, action,
                'isInventoryPalletContentsLoading',
                'InventoryPalletContentsData',
                types.INVENTORY_PALLET_CONTENTS_GET,
                types.INVENTORY_PALLET_CONTENTS_COMPLETE);


        case types.INVENTORY_POST_LOAD_BARCODE_SCAN_GET:
            return Object.assign({}, state, {
                InventoryLoadBarcodeScanPostSuccessful: false,
                isInventoryPostLoading: true
            });
        case types.INVENTORY_POST_LOAD_BARCODE_SCAN_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            var msgCode;
            try {
                msgCode = newData[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    InventoryLoadBarcodeScanPostSuccessful: true,
                    isInventoryPostLoading: false

                });
            }
            else {
                try {
                    window.alert(newData[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    InventoryLoadBarcodeScanPostSuccessful: false,
                    isInventoryPostLoading: false
                });
            }


        case types.INVENTORY_POST_PALLET_CREATE_NEW_GET:
            return Object.assign({}, state, {
                InventoryPalletCreateNewPostSuccessful: false,
                InventoryNewPalletBarcode: null,
                isInventoryPostLoading: true
            });
        case types.INVENTORY_POST_PALLET_CREATE_NEW_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            var msgCode;
            var palletBarcode;
            try {
                msgCode = newData[0].MsgCode

                palletBarcode = newData[0].PalletBarcode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    InventoryPalletCreateNewPostSuccessful: true,
                    InventoryNewPalletBarcode: palletBarcode,
                    isInventoryPostLoading: false

                });
            }
            else {
                try {
                    window.alert(newData[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    InventoryPalletCreateNewPostSuccessful: false,
                    InventoryNewPalletBarcode: null,
                    isInventoryPostLoading: false
                });
            }



        case types.INVENTORY_SKU_REF_GET:
        case types.INVENTORY_SKU_REF_COMPLETE:
            return handleAction(state, action,
                'isInventorySkuRefLoading',
                'InventorySkuRefData',
                types.INVENTORY_SKU_REF_GET,
                types.INVENTORY_SKU_REF_COMPLETE);

        case types.INVENTORY_CASE_HISTORY_GET:
        case types.INVENTORY_CASE_HISTORY_COMPLETE:
            return handleAction(state, action,
                'isInventoryCaseHistoryLoading',
                'InventoryCaseHistoryData',
                types.INVENTORY_CASE_HISTORY_GET,
                types.INVENTORY_CASE_HISTORY_COMPLETE);

        case types.INVENTORY_CASE_SKUS_HISTORY_GET:
        case types.INVENTORY_CASE_SKUS_HISTORY_COMPLETE:
            return handleAction(state, action,
                'isInventoryCaseSkusHistoryLoading',
                'InventoryCaseSkusHistoryData',
                types.INVENTORY_CASE_SKUS_HISTORY_GET,
                types.INVENTORY_CASE_SKUS_HISTORY_COMPLETE);

        case types.INVENTORY_LOAD_CASES_HISTORY_GET:
        case types.INVENTORY_LOAD_CASES_HISTORY_COMPLETE:
            return handleAction(state, action,
                'isInventoryLoadCasesHistoryLoading',
                'InventoryLoadCasesHistoryData',
                types.INVENTORY_LOAD_CASES_HISTORY_GET,
                types.INVENTORY_LOAD_CASES_HISTORY_COMPLETE);

        case types.INVENTORY_LOAD_PALLETS_HISTORY_GET:
        case types.INVENTORY_LOAD_PALLETS_HISTORY_COMPLETE:
            return handleAction(state, action,
                'isInventoryLoadPalletsHistoryLoading',
                'InventoryLoadPalletsHistoryData',
                types.INVENTORY_LOAD_PALLETS_HISTORY_GET,
                types.INVENTORY_LOAD_PALLETS_HISTORY_COMPLETE);


        case types.INVENTORY_LOAD_SKUS_HISTORY_GET:
        case types.INVENTORY_LOAD_SKUS_HISTORY_COMPLETE:
            return handleAction(state, action,
                'isInventoryLoadSKUsHistoryLoading',
                'InventoryLoadSKUsHistoryData',
                types.INVENTORY_LOAD_SKUS_HISTORY_GET,
                types.INVENTORY_LOAD_SKUS_HISTORY_COMPLETE);

        case types.INVENTORY_PALLET_WAREHOUSE_LOCATION_HISTORY_GET:
        case types.INVENTORY_PALLET_WAREHOUSE_LOCATION_HISTORY_COMPLETE:
            return handleAction(state, action,
                'isInventoryPalletWarehouseLocationHistoryLoading',
                'InventoryPalletWarehouseLocationHistoryData',
                types.INVENTORY_PALLET_WAREHOUSE_LOCATION_HISTORY_GET,
                types.INVENTORY_PALLET_WAREHOUSE_LOCATION_HISTORY_COMPLETE);

        case types.INVENTORY_POST_LOAD_REMOVE_PALLET_CASE_SKU_GET:
            return Object.assign({}, state, {
                InventoryLoadRemovePalletCaseSkuPostSuccessful: false
            });
        case types.INVENTORY_POST_LOAD_REMOVE_PALLET_CASE_SKU_COMPLETE:
            return handleInventoryPost1(state, action, 'InventoryLoadRemovePalletCaseSkuPostSuccessful');

        case types.INVENTORY_LOT_BARCODE_SCAN_VALIDATE_GET:
            return handleInventoryGet(state, action, true, 'isInventoryLotBarcodeScanValidateLoading', 'InventoryLotBarcodeScanValidateData');
        case types.INVENTORY_LOT_BARCODE_SCAN_VALIDATE_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError();
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            return handleInventoryGet(state, action, false, 'isInventoryLotBarcodeScanValidateLoading', 'InventoryLotBarcodeScanValidateData', newData);



        case types.INVENTORY_LOT_OUTBOUND_GET:
        case types.INVENTORY_LOT_OUTBOUND_COMPLETE:
            return handleAction(state, action,
                'isInventoryLotOutboundLoading',
                'InventoryLotOutboundData',
                types.INVENTORY_LOT_OUTBOUND_GET,
                types.INVENTORY_LOT_OUTBOUND_COMPLETE);

        case types.INVENTORY_LOT_REPORTING_INBOUND_GET:
        case types.INVENTORY_LOT_REPORTING_INBOUND_COMPLETE:
            return handleAction(state, action,
                'isInventoryLotReportingInboundLoading',
                'InventoryLotReportingInboundData',
                types.INVENTORY_LOT_REPORTING_INBOUND_GET,
                types.INVENTORY_LOT_REPORTING_INBOUND_COMPLETE);

        case types.INVENTORY_LOT_REPORTING_STORAGE_GET:
        case types.INVENTORY_LOT_REPORTING_STORAGE_COMPLETE:
            return handleAction(state, action,
                'isInventoryLotReportingStorageLoading',
                'InventoryLotReportingStorageData',
                types.INVENTORY_LOT_REPORTING_STORAGE_GET,
                types.INVENTORY_LOT_REPORTING_STORAGE_COMPLETE);


        case types.INVENTORY_CASE_WAREHOUSE_LOCATION_GET:
        case types.INVENTORY_CASE_WAREHOUSE_LOCATION_COMPLETE:
            return handleAction(state, action,
                'isInventoryCaseWarehouseLoading',
                'InventoryCaseWarehouseData',
                types.INVENTORY_CASE_WAREHOUSE_LOCATION_GET,
                types.INVENTORY_CASE_WAREHOUSE_LOCATION_COMPLETE);

        case types.INVENTORY_PALLET_WAREHOUSE_LOCATION_GET:
        case types.INVENTORY_PALLET_WAREHOUSE_LOCATION_COMPLETE:
            return handleAction(state, action,
                'isInventoryPalletWarehouseLoading',
                'InventoryPalletWarehouseData',
                types.INVENTORY_PALLET_WAREHOUSE_LOCATION_GET,
                types.INVENTORY_PALLET_WAREHOUSE_LOCATION_COMPLETE);



        case types.INVENTORY_LOT_HISTORY_TYPE_GET:
        case types.INVENTORY_LOT_HISTORY_TYPE_COMPLETE:
            return handleAction(state, action,
                'isInventoryLotHistoryTypeLoading',
                'InventoryLotHistoryTypeData',
                types.INVENTORY_LOT_HISTORY_TYPE_GET,
                types.INVENTORY_LOT_HISTORY_TYPE_COMPLETE);



        case types.INVENTORY_LOT_POST_DELETE_CONTAINER_PALLET_CASE_UNIT_GET:
            return Object.assign({}, state, {
                InventoryDeleteContainerPalletCaseUnitPostSuccessful: false,
                isInventoryDeleteContainerPalletCaseUnitLoading: true
            });
        case types.INVENTORY_LOT_POST_DELETE_CONTAINER_PALLET_CASE_UNIT_COMPLETE:
            return handlePostAction(state, action, 'isInventoryDeleteContainerPalletCaseUnitLoading', 'InventoryDeleteContainerPalletCaseUnitPostSuccessful');

        case types.INVENTORY_LOT_POST_CASE_REF_EDIT_GET:
            return Object.assign({}, state, {
                InventoryLotCaseRefEditPostSuccessful: false,
                isInventoryLotCaseRefEditLoading: true
            });
        case types.INVENTORY_LOT_POST_CASE_REF_EDIT_COMPLETE:
            return handlePostAction(state, action, 'isInventoryLotCaseRefEditLoading', 'InventoryLotCaseRefEditPostSuccessful');

        case types.INVENTORY_LOT_POST_UNIT_CASESKU_REF_EDIT_GET:
            return Object.assign({}, state, {
                InventoryLotUnitCaseSkuRefEditPostSuccessful: false,
                isInventoryLotUnitCaseSkuRefEditLoading: true
            });
        case types.INVENTORY_LOT_POST_UNIT_CASESKU_REF_EDIT_COMPLETE:
            return handlePostAction(state, action, 'isInventoryLotUnitCaseSkuRefEditLoading', 'InventoryLotUnitCaseSkuRefEditPostSuccessful');

        case types.INVENTORY_LOT_POST_PALLET_REF_EDIT_GET:
            return Object.assign({}, state, {
                InventoryLotPalletEditPostSuccessful: false,
                isInventoryLotPalletRefEditLoading: true
            });
        case types.INVENTORY_LOT_POST_PALLET_REF_EDIT_COMPLETE:
            return handlePostAction(state, action, 'isInventoryLotPalletRefEditLoading', 'InventoryLotPalletEditPostSuccessful');



        case types.INVENTORY_LOT_POST_CONTAINER_GET:
            return Object.assign({}, state, {
                InventoryLotContainerPostSuccessful: false,
                NewLotContainerId: null,
                isInventoryPostLoading: true
            });
        case types.INVENTORY_LOT_POST_CONTAINER_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            var msgCode;
            var newID;
            try {
                msgCode = newData[0].MsgCode
                newID = newData[0].NewContainerId
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    InventoryLotContainerPostSuccessful: true,
                    NewLotContainerId: newID,
                    isInventoryPostLoading: false
                });
            }
            else {
                try {
                    window.alert(newData[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    InventoryLotContainerPostSuccessful: false,
                    NewLotContainerId: null,
                    isInventoryPostLoading: false
                });
            }


        case types.INVENTORY_LOT_ORDER_OUTBOUND_POST:
            return Object.assign({}, state, {
                InventoryLotOrderOutboundPostSuccessful: false,
                NewOutboundOrderId: null,
                isInventoryLotOrderOutboundLoading: true
            });
        case types.INVENTORY_LOT_ORDER_OUTBOUND_POST_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            var msgCode;
            var newID;
            try {
                msgCode = newData[0].MsgCode
                newID = newData[0].NewOrderId
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    InventoryLotOrderOutboundPostSuccessful: true,
                    NewOutboundOrderId: newID,
                    isInventoryLotOrderOutboundLoading: false
                });
            }
            else {
                try {
                    window.alert(newData[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    InventoryLotOrderOutboundPostSuccessful: false,
                    NewOutboundOrderId: null,
                    isInventoryLotOrderOutboundLoading: false
                });
            }



        case types.INVENTORY_LOT_ORDER_OUTBOUND_SKU_POST:
            return Object.assign({}, state, {
                isInventoryLotOrderOutboundSkuLoading: true,
                InventoryLotOrderOutboundSkuPostSuccessful: false
            });
        case types.INVENTORY_LOT_ORDER_OUTBOUND_SKU_POST_COMPLETE:
            return handlePostAction(state, action, 'isInventoryLotOrderOutboundSkuLoading', 'InventoryLotOrderOutboundSkuPostSuccessful');



        case types.INVENTORY_LOT_ORDER_OUTBOUND_GET:
        case types.INVENTORY_LOT_ORDER_OUTBOUND_GET_COMPLETE:
            return handleAction(state, action,
                'isInventoryLotOrderOutboundGetLoading',
                'InventoryLotOrderOutboundData',
                types.INVENTORY_LOT_ORDER_OUTBOUND_GET,
                types.INVENTORY_LOT_ORDER_OUTBOUND_GET_COMPLETE);


        case types.INVENTORY_LOT_ORDER_OUTBOUND_SKU_GET:
        case types.INVENTORY_LOT_ORDER_OUTBOUND_SKU_GET_COMPLETE:
            return handleAction(state, action,
                'isInventoryLotOrderOutboundSkuGetLoading',
                'InventoryLotOrderOutboundSkuData',
                types.INVENTORY_LOT_ORDER_OUTBOUND_SKU_GET,
                types.INVENTORY_LOT_ORDER_OUTBOUND_SKU_GET_COMPLETE);


        case types.INVENTORY_LOT_ORDER_OUTBOUND_SKU_EACH_LOCATIONS_GET:
        case types.INVENTORY_LOT_ORDER_OUTBOUND_SKU_EACH_LOCATIONS_GET_COMPLETE:
            return handleAction(state, action,
                'isInventoryLotOrderOutboundSkuEachLocationsGetLoading',
                'InventoryLotOrderOutboundSkuEachLocationsData',
                types.INVENTORY_LOT_ORDER_OUTBOUND_SKU_EACH_LOCATIONS_GET,
                types.INVENTORY_LOT_ORDER_OUTBOUND_SKU_EACH_LOCATIONS_GET_COMPLETE);


        case types.INVENTORY_LOT_ORDER_OUTBOUND_SKU_CASE_LOCATIONS_GET:
        case types.INVENTORY_LOT_ORDER_OUTBOUND_SKU_CASE_LOCATIONS_GET_COMPLETE:
            return handleAction(state, action,
                'isInventoryLotOrderOutboundSkuCaseLocationsGetLoading',
                'InventoryLotOrderOutboundSkuCaseLocationsData',
                types.INVENTORY_LOT_ORDER_OUTBOUND_SKU_CASE_LOCATIONS_GET,
                types.INVENTORY_LOT_ORDER_OUTBOUND_SKU_CASE_LOCATIONS_GET_COMPLETE);


        case types.INVENTORY_LOT_PALLET_SKU_UNITS_GET:
        case types.INVENTORY_LOT_PALLET_SKU_UNITS_GET_COMPLETE:
            return handleAction(state, action,
                'isInventoryLotPalletSkuUnitsGetLoading',
                'InventoryLotPalletSkuUnitsData',
                types.INVENTORY_LOT_PALLET_SKU_UNITS_GET,
                types.INVENTORY_LOT_PALLET_SKU_UNITS_GET_COMPLETE);


        case types.INVENTORY_LOT_PALLET_SKU_CASES_GET:
        case types.INVENTORY_LOT_PALLET_SKU_CASES_GET_COMPLETE:
            return handleAction(state, action,
                'isInventoryLotPalletSkuCasesGetLoading',
                'InventoryLotPalletSkuCasesData',
                types.INVENTORY_LOT_PALLET_SKU_CASES_GET,
                types.INVENTORY_LOT_PALLET_SKU_CASES_GET_COMPLETE);


        case types.INVENTORY_LOT_CASE_SKU_UNITS_GET:
        case types.INVENTORY_LOT_CASE_SKU_UNITS_GET_COMPLETE:
            return handleAction(state, action,
                'isInventoryLotCaseSkuUnitsGetLoading',
                'InventoryLotCaseSkuUnitsData',
                types.INVENTORY_LOT_CASE_SKU_UNITS_GET,
                types.INVENTORY_LOT_CASE_SKU_UNITS_GET_COMPLETE);



        case types.INVENTORY_LOT_ORDER_OUTBOUND_SKU_PALLET_LOCATIONS_GET:
        case types.INVENTORY_LOT_ORDER_OUTBOUND_SKU_PALLET_LOCATIONS_GET_COMPLETE:
            return handleAction(state, action,
                'isInventoryLotOrderOutboundSkuPalletLocationsGetLoading',
                'InventoryLotOrderOutboundSkuPalletLocationsData',
                types.INVENTORY_LOT_ORDER_OUTBOUND_SKU_PALLET_LOCATIONS_GET,
                types.INVENTORY_LOT_ORDER_OUTBOUND_SKU_PALLET_LOCATIONS_GET_COMPLETE);

        case types.INVENTORY_LOT_ORDER_OUTBOUND_HISTORY_GET:
        case types.INVENTORY_LOT_ORDER_OUTBOUND_HISTORY_GET_COMPLETE:
            return handleAction(state, action,
                'isInventoryLotOrderOutboundHistoryGetLoading',
                'InventoryLotOrderOutboundHistoryData',
                types.INVENTORY_LOT_ORDER_OUTBOUND_HISTORY_GET,
                types.INVENTORY_LOT_ORDER_OUTBOUND_HISTORY_GET_COMPLETE);







        case types.INVENTORY_LOT_LOAD_FILEUPLOAD_GET:
            return Object.assign({}, state, {
                InventoryLotLoadFileUploadPostSuccessful: true
            });
        case types.INVENTORY_LOT_LOAD_FILEUPLOAD_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                actionOnError()
                return state;
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            return Object.assign({}, state, {
                InventoryLotLoadFileUploadPostSuccessful: false,
                InventoryLotLoadFileUploadData: newData
            });

        case types.INVENTORY_LOT_POST_LOAD_FILEUPLOAD_GET:
            return Object.assign({}, state, {
                InventoryLotLoadFileUploadPostSuccessful: false
            });
        case types.INVENTORY_LOT_POST_LOAD_FILEUPLOAD_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                actionOnError()
                return state;
            }
            var compressedBody = JSON.parse(action.payload.body);
            var newData = decodeAndDecompress(compressedBody.compressed_data);
            var msgCode;
            try {
                msgCode = newData[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    InventoryLotLoadFileUploadPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    InventoryLotLoadFileUploadPostSuccessful: false
                });
            }





            case types.INVENTORY_LOT_SKU_INBOUND_RECEIPTS_GET:
                case types.INVENTORY_LOT_SKU_INBOUND_RECEIPTS_GET_COMPLETE:
                    return handleAction(state, action,
                        'isInventoryLotSkuInboundReceiptsGetLoading',
                        'InventoryLotSkuInboundReceiptsData',
                        types.INVENTORY_LOT_SKU_INBOUND_RECEIPTS_GET,
                        types.INVENTORY_LOT_SKU_INBOUND_RECEIPTS_GET_COMPLETE);










        default:
            return state;
    }
};





export default InventoryReducer;




//this is compressed data function expanded: 
// case types.LABELGEN_EXTERNAL_UPS_TRACKING_GET:
//     return Object.assign({}, state, {
//         isLabelGenExternalUpsTrackingLoading: true,
//     });
// case types.LABELGEN_EXTERNAL_UPS_TRACKING_COMPLETE:
//     if (!checkStatusCode(action.payload)) {
//         return actionOnError()
//     }
//     try {
//         var compressedBody = JSON.parse(action.payload.body);
//         var binaryString = atob(compressedBody.compressed_data);
//         var len = binaryString.length;
//         var bytes = new Uint8Array(len);
//         for (var i = 0; i < len; i++) {
//             bytes[i] = binaryString.charCodeAt(i);
//         }
//         var decompressedData = pako.inflate(bytes, { to: 'string' });
//         var newData = JSON.parse(decompressedData);
//         return Object.assign({}, state, {
//             isLabelGenExternalUpsTrackingLoading: false,
//             LabelGenExternalUpsTrackingData: newData
//         });
//     } catch (error) {
//         console.error('Error decoding base64 data:', error);
//         return Object.assign({}, state, {
//             isLabelGenExternalUpsTrackingLoading: false,
//             LabelGenExternalUpsTrackingData: []
//         });
//     }
