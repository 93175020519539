import { connect } from 'react-redux';
import React, { Component } from 'react';
import InventorySkuLevel from './InventorySkuLevel';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as inventoryActions from '../../../../redux/actions/inventoryActions'
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../../resources/logic/functions';
import * as userActions from '../../../../redux/actions/actionsUser'

const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isWarehouseLocationsLoading: store.userServicesReducer.isWarehouseLocationsLoading,
    WarehouseLocationsData: store.userServicesReducer.WarehouseLocationsData,

    isInventorySkuWarehouseGroupedLoading: store.inventoryReducer.isInventorySkuWarehouseGroupedLoading,
    InventorySkuWarehouseGroupedData: store.inventoryReducer.InventorySkuWarehouseGroupedData,

    isInventoryLotSkuLoading: store.inventoryReducer.isInventoryLotSkuLoading,
    InventoryLotSkuData: store.inventoryReducer.InventoryLotSkuData,

    isInventorySkuWarehouseLocationLoading: store.inventoryReducer.isInventorySkuWarehouseLocationLoading,
    InventorySkuWarehouseLocationData: store.inventoryReducer.InventorySkuWarehouseLocationData,
    SkuLocationDataGrouped: store.inventoryReducer.SkuLocationDataGrouped,



    isInventoryDestinationLoading: store.inventoryReducer.isInventoryDestinationLoading,
    InventoryDestinationData: store.inventoryReducer.InventoryDestinationData,



    isInventorySkuWarehouseLoading: store.inventoryReducer.isInventorySkuWarehouseLoading,
    InventorySkuWarehouseData: store.inventoryReducer.InventorySkuWarehouseData,

    isInventoryCaseWarehouseLoading: store.inventoryReducer.isInventoryCaseWarehouseLoading,
    InventoryCaseWarehouseData: store.inventoryReducer.InventoryCaseWarehouseData,

    isInventoryPalletWarehouseLoading: store.inventoryReducer.isInventoryPalletWarehouseLoading,
    InventoryPalletWarehouseData: store.inventoryReducer.InventoryPalletWarehouseData,

    InventoryLoadPalletCaseSkuPostSuccessful: store.inventoryReducer.InventoryLoadPalletCaseSkuPostSuccessful,


    InventoryLoadPalletCaseSkuPostLoading: store.inventoryReducer.InventoryLoadPalletCaseSkuPostLoading
});

const mapDispatchToProps = dispatch =>
({
    userWarehouseLocationsGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(userActions.userWarehouseLocationsGet(_email, _authToken, _WarehouseID, _CustomerID))
    },

    inventorySkuWarehouseLocationGroupedGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocationGroupedGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID))
    },


    inventoryLotSkuGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventoryLotSkuGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },
    inventorySkuWarehouseLocGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },

    inventoryDestinationGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryDestinationGet(_email, _authToken, _WarehouseID, _CustomerID))
    },


    inventoryLotSkuWarehouseLocationGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID) => {
        dispatch(inventoryActions.inventoryLotSkuWarehouseLocationGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID))
    },

    inventoryCaseWarehouseLocationGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd) => {
        dispatch(inventoryActions.inventoryCaseWarehouseLocationGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd))
    },

    inventoryPalletWarehouseLocationGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize) => {
        dispatch(inventoryActions.inventoryPalletWarehouseLocationGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize))
    },


    inventoryLoadPalletCaseSkuPost: (_email, _authToken, _WarehouseID, _CustomerID, _DataJson, _DataType, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalUnits, _TotalCases, _TotalPallets, _Destination, _DestinationID, _LoadStatusID, _OrderOutboundID, _MC, _DOT, _Driver, _Truck, _TruckPlate, _TrailerPlate, _Notes) => {
        dispatch(inventoryActions.inventoryLoadPalletCaseSkuPost(_email, _authToken, _WarehouseID, _CustomerID, _DataJson, _DataType, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalUnits, _TotalCases, _TotalPallets, _Destination, _DestinationID, _LoadStatusID, _OrderOutboundID, _MC, _DOT, _Driver, _Truck, _TruckPlate, _TrailerPlate, _Notes))
    },




});

class InventorySkuLevelContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        if (prevProps.InventoryLoadPalletCaseSkuPostSuccessful !== this.props.InventoryLoadPalletCaseSkuPostSuccessful && this.props.InventoryLoadPalletCaseSkuPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }

    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramContainer = formatStringForAPI(values.container)
        }
        catch (e) {
            var paramContainer = null
        }
        try {
            var paramStatusID = formatStringForAPI(values.statusid)
        }
        catch (e) {
            var paramStatusID = null
        }


        try {
            var paramRecoveryDateStart = formatDateForAPI(values.recoverydtstart)
        }
        catch (e) {
            var paramRecoveryDateStart = null
        }
        try {
            var paramRecoveryDateEnd = formatDateForAPI(values.recoverydtend)
        }
        catch (e) {
            var paramRecoveryDateEnd = null
        }


        try {
            var paramWarehouseLocationId = formatDateForAPI(values.warehouselocationid)
        }
        catch (e) {
            var paramWarehouseLocationId = null
        }


        try {
            var paramPalletbarcode = formatStringForAPI(values.palletbarcode)
        }
        catch (e) {
            var paramPalletbarcode = null
        }

        try {
            var paramCasesku = formatStringForAPI(values.casesku)
        }
        catch (e) {
            var paramCasesku = null
        }
        try {
            var paramcasebarcode = formatStringForAPI(values.casebarcode)
        }
        catch (e) {
            var paramcasebarcode = null
        }
        try {
            var paramcaseserial = formatStringForAPI(values.caseserial)
        }
        catch (e) {
            var paramcaseserial = null
        }
        try {
            var paramcaselotcode = formatStringForAPI(values.caselotcode)
        }
        catch (e) {
            var paramcaselotcode = null
        }
        try {
            var paramunitsku = formatStringForAPI(values.unitsku)
        }
        catch (e) {
            var paramunitsku = null
        }
        try {
            var paramunitserial = formatStringForAPI(values.unitserial)
        }
        catch (e) {
            var paramunitserial = null
        }
        try {
            var paramunitlotcode = formatStringForAPI(values.unitlotcode)
        }
        catch (e) {
            var paramunitlotcode = null
        }



        // this.props.inventoryLotSkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, paramSku, null, paramContainer, paramStatus, paramWarehouseLocationId)
        // this.props.inventorySkuWarehouseLocGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, paramSku, null, paramContainer, paramStatus, paramWarehouseLocationId)

        this.props.userWarehouseLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);



        this.props.inventoryDestinationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);


        

        this.props.inventorySkuWarehouseLocationGroupedGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramWarehouseLocationId, this.props.warehouseSelection, this.props.customerSelection, null, null, paramStatusID, null, paramPalletbarcode, null, paramcasebarcode, null, paramCasesku, paramcaselotcode, paramcaseserial, null, paramunitsku, paramunitlotcode, paramunitserial, null, paramContainer, paramRecoveryDateStart, paramRecoveryDateEnd, null)
       

    }



    render() {

        return (

            <InventorySkuLevel
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isInventorySkuWarehouseGroupedLoading={this.props.isInventorySkuWarehouseGroupedLoading}
                InventorySkuWarehouseGroupedData={this.props.InventorySkuWarehouseGroupedData}

                isWarehouseLocationsLoading={this.props.isWarehouseLocationsLoading}
                WarehouseLocationsData={this.props.WarehouseLocationsData}


                isInventoryLotSkuLoading={this.props.isInventoryLotSkuLoading}
                InventoryLotSkuData={this.props.InventoryLotSkuData}

                isInventorySkuWarehouseLocationLoading={this.props.isInventorySkuWarehouseLocationLoading}
                InventorySkuWarehouseLocationData={this.props.InventorySkuWarehouseLocationData}
                SkuLocationDataGrouped={this.props.SkuLocationDataGrouped}



                isInventorySkuWarehouseLoading={this.props.isInventorySkuWarehouseLoading}
                InventorySkuWarehouseData={this.props.InventorySkuWarehouseData}

                isInventoryCaseWarehouseLoading={this.props.isInventoryCaseWarehouseLoading}
                InventoryCaseWarehouseData={this.props.InventoryCaseWarehouseData}

                isInventoryPalletWarehouseLoading={this.props.isInventoryPalletWarehouseLoading}
                InventoryPalletWarehouseData={this.props.InventoryPalletWarehouseData}

                InventoryLoadPalletCaseSkuPostLoading={this.props.InventoryLoadPalletCaseSkuPostLoading}
                InventoryLoadPalletCaseSkuPostSuccessful={this.props.InventoryLoadPalletCaseSkuPostSuccessful}
                inventoryLoadPalletCaseSkuPost={this.props.inventoryLoadPalletCaseSkuPost}

            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InventorySkuLevelContainer));



