import React from 'react';
import sampleIcon from '../resources/img/evolveLogoPng.png';
import evolveLogoPng from '../resources/img/evolveLogoPng.png';
import { appSettings } from './appSettings';

export const appConfig = {

  //App values
  appName: 'Evolve SCS Portal',

  //Icons
  webBrowserIcon: sampleIcon,
  largeEvolveIcon: evolveLogoPng,


  colors: {
    red: '#F05050',
    blue: '#23b7e5',
    yellow: '#F8C471',
    green: '#27C24C',
    violet: '#7266ba',
    darkblue: '#34495E',
    lightgrey: '#ABB2B9'

  },

};

// export const lotTrackingWarehouseCodes = ["EVO", "JFKEM", "CTB", "CLT", "ONT", "LW1", "ORD", "JKA"]

let lotTrackingWarehouseCodes;

if (appSettings.environment == "prod") {
  lotTrackingWarehouseCodes = ["EVO", "JFKEM", "CTB", "CLT", "ONT", "LW1", "ORD", "JKA", "EVODC", "LATRI"];
} else if (appSettings.environment == "ext") {
  lotTrackingWarehouseCodes = ["All"];
} else if (appSettings.environment == "dev") {
  lotTrackingWarehouseCodes = ["All"];
}

export { lotTrackingWarehouseCodes };



let lotTrackingWarehouseIDs;
if (appSettings.environment == "prod") {
  lotTrackingWarehouseIDs = [1, 8, 9, 10, 11, 12, 13,15, 16];
} else if (appSettings.environment == "ext") {
  lotTrackingWarehouseIDs = ["All"];
} else if (appSettings.environment == "dev") {
  lotTrackingWarehouseIDs = ["All"];
}

export { lotTrackingWarehouseIDs };



// export const lotTrackingWarehouseIDs = [1, 8, 9, 10, 11, 12, 13]

export const appAPI = {
  //API
  API: {
    data: {


      API_RunStoredProc: (
        appSettings.environment === "dev" ? 'https://x6vw75xwgi.execute-api.us-west-1.amazonaws.com/dev/wms_webapp_run_proc' :  // dev
          appSettings.environment === "prod" ? 'https://knaapsdoag.execute-api.us-east-1.amazonaws.com/prod/vas_webapp_run_proc' :  // prod
            appSettings.environment === "ext" ? 'xxxxx' :  // ext
              null
      ),



      //Run Proc Compressed Data:
      WebAppRunProcCompressed: (
        appSettings.environment === "dev" ? 'https://x6vw75xwgi.execute-api.us-west-1.amazonaws.com/dev/WebApp_Run_Proc_Compressed' :  // dev
          appSettings.environment === "prod" ? 'https://knaapsdoag.execute-api.us-east-1.amazonaws.com/prod/WMS_WebApp_Run_Proc_Compresssed' :  // prod
            appSettings.environment === "ext" ? 'https://q3ck8q4508.execute-api.us-east-1.amazonaws.com/prod/WMS_EFW_WebApp_Run_Proc_Compressed' :  // ext
              null
      ),


      //User API's:
      WMS_User_NewSignUp: (
        appSettings.environment === "dev" ? 'https://x6vw75xwgi.execute-api.us-west-1.amazonaws.com/dev/wms_user_newsignup' :  // dev
          appSettings.environment === "prod" ? 'https://knaapsdoag.execute-api.us-east-1.amazonaws.com/prod/vas_webapp_user_signup' :  // prod
            appSettings.environment === "ext" ? 'xxxx' :  // ext
              null
      ),

      User_NewSignUp_API_Key: (
        appSettings.environment === "dev" ? 'tl9egWnjoM7NY9Gq3nr1J2Zr5NHJwlnj7IgKwdrQ' :  // dev
          appSettings.environment === "prod" ? 'V4WISXWE2n9O2ADP4hnLJ4V5Tf63ILUg9Txt1LKa' :  // prod
            appSettings.environment === "ext" ? 'xxxx' :  // ext
              null
      ),




      //Customs City API:
      CustomsCityAPI: (
        appSettings.environment === "dev" ? 'https://x6vw75xwgi.execute-api.us-west-1.amazonaws.com/dev/wms_customs_city_airams_messages_save' :  // dev
          appSettings.environment === "prod" ? 'https://knaapsdoag.execute-api.us-east-1.amazonaws.com/prod/vas_customs_city_airams_messages_save' :  // prod
            appSettings.environment === "ext" ? 'https://x6vw75xwgi.execute-api.us-west-1.amazonaws.com/dev/wms_customs_city_airams_messages_save' :  // ext --this is the dev api
              null
      ),


      //FileUpload API:
      FileUploadAPI: (
        appSettings.environment === "dev" ? 'https://f4ydgioz3k.execute-api.us-west-1.amazonaws.com/dev/' :  // dev
          appSettings.environment === "prod" ? 'https://ra6kbgqgm8.execute-api.us-east-1.amazonaws.com/prod/' :  // prod
            appSettings.environment === "ext" ? 'https://f4ydgioz3k.execute-api.us-west-1.amazonaws.com/dev/' :  // ext
              null
      ),


      //FileDownload API:
      FileDownloadAPI: (
        appSettings.environment === "dev" ? 'https://x6vw75xwgi.execute-api.us-west-1.amazonaws.com/dev/wms_webapp_filedownloads3' :  // dev
          appSettings.environment === "prod" ? 'https://knaapsdoag.execute-api.us-east-1.amazonaws.com/prod/vas_webapp_filedownloads3' :  // prod
            appSettings.environment === "ext" ? 'https://q3ck8q4508.execute-api.us-east-1.amazonaws.com/prod/WMS_EFW_WebApp_File_Download_S3' :  // ext
              null
      ),

      //Control Tower FileDownload S3 Bucket:
      FileDownloadCtrlTwrS3: (
        appSettings.environment === "dev" ? 'wms-fileupload-dev' :  // dev
          appSettings.environment === "prod" ? 'vas-webapp-fileupload-ctrltwr' :  // prod
            appSettings.environment === "ext" ? 'xxxx' :  // ext
              null
      ),


      //Inventory FileDownload S3 Bucket:
      FileDownloadInventoryS3: (
        appSettings.environment === "dev" ? 'wms-fileupload-dev' :  // dev
          appSettings.environment === "prod" ? 'vas-webapp-fileupload-inventory' :  // prod
            appSettings.environment === "ext" ? 'wms-ext-webapp-fileupload-inventory' :  // ext
              null
      ),


      //XDock FileDownload S3 Bucket:
      FileDownloadXDockS3: (
        appSettings.environment === "dev" ? 'wms-fileupload-dev' :  // dev
          appSettings.environment === "prod" ? 'vas-webapp-fileupload-xdock-mawb' :  // prod
            appSettings.environment === "ext" ? 'wms-ext-webapp-fileupload-xdock-mawb' :  // ext
              null
      ),

      //XDock Trinity Data S3 Bucket:
      XDockTrinityDataFileS3: (
        appSettings.environment === "dev" ? 'wms-fileupload-dev' :  // dev
          appSettings.environment === "prod" ? 'vas-data-load-processed' :  // prod
            appSettings.environment === "ext" ? 'wms-ext-data-load-processed' :  // ext
              null
      ),

      //Transload FileDownload S3 Bucket:
      FileDownloadTransloadS3: (
        appSettings.environment === "dev" ? 'wms-fileupload-dev' :  // dev
          appSettings.environment === "prod" ? 'vas-webapp-fileupload-transload-container' :  // prod
            appSettings.environment === "ext" ? 'wms-ext-webapp-fileupload-transload-container' :  // ext
              null
      ),

      //Label Gen S3 Bucket:
      LabelGenDownloadS3: (
        appSettings.environment === "dev" ? 'wms-labelgen-labels-dev' :  // dev
          appSettings.environment === "prod" ? 'vas-labelgen-labels-prod' :  // prod
            appSettings.environment === "ext" ? 'wms-ext-labelgen-labels-prod' :  // ext
              null
      ),


      //Label Gen S3 Bucket:
      ParcelTrackingDownloadS3: (
        appSettings.environment === "dev" ? 'wms-webapp-query-results' :  // dev
          appSettings.environment === "prod" ? 'vas-webapp-query-results' :  // prod
            appSettings.environment === "ext" ? 'wms-ext-webapp-query-results' :  // ext
              null
      ),



      //LabelGen API:
      LabelGenRequestMain: (
        appSettings.environment === "dev" ? 'https://x6vw75xwgi.execute-api.us-west-1.amazonaws.com/dev/wms_labelgen_request_dev' :  // dev
          appSettings.environment === "prod" ? 'https://knaapsdoag.execute-api.us-east-1.amazonaws.com/prod/vas_labelgen_request_main' :  // prod
            appSettings.environment === "ext" ? 'xxxx' :  // ext
              null
      ),


      //TMS API's:  Hong Kong
      API_Evolve_TMS_Ship_Request: (
        appSettings.environment === "dev" ? 'https://jhn3ylv8pl.execute-api.ap-east-1.amazonaws.com/test/evolve-tms-ship-request' :  // dev
          appSettings.environment === "prod" ? 'https://jhn3ylv8pl.execute-api.ap-east-1.amazonaws.com/prod/evolve-tms-ship-request' :  // prod
            appSettings.environment === "ext" ? 'xxxx' :  // ext
              null
      ),


      //UPS APIs:  N Virginia // not in use testing only
      // API_ups_api_label_creation: 'https://zh0lq4h26g.execute-api.us-east-1.amazonaws.com/prod/ups-api-label-creation',  // calls lambda ups_api_label_creation, hits UPS api




      //Run Proc Download to S3 API:
      WebAppRunProcToS3: (
        appSettings.environment === "dev" ? 'https://x6vw75xwgi.execute-api.us-west-1.amazonaws.com/dev/WMS_WebApp_Run_Proc_To_S3' :  // dev
          appSettings.environment === "prod" ? 'https://knaapsdoag.execute-api.us-east-1.amazonaws.com/prod/VAS_WebApp_Run_Proc_To_S3' :  // prod
            appSettings.environment === "ext" ? 'xxxx' :  // ext
              null
      ),



      //Run Proc Directly Download to S3 API:
      WebAppRunProcDirectlyToS3: (
        appSettings.environment === "dev" ? 'https://x6vw75xwgi.execute-api.us-west-1.amazonaws.com/dev/WMS_WebApp_Run_Proc_Directly_To_S3' :  // dev
          appSettings.environment === "prod" ? 'https://knaapsdoag.execute-api.us-east-1.amazonaws.com/prod/VAS_WebApp_Run_Proc_Directly_To_S3' :  // prod
            appSettings.environment === "ext" ? 'https://q3ck8q4508.execute-api.us-east-1.amazonaws.com/prod/WMS_EFW_WebApp_Run_Proc_Directly_To_S3' :  // ext
              null
      ),


      //AWS Cognito:
      AwsCognitoDeleteUserAPI: (
        appSettings.environment === "dev" ? 'https://x6vw75xwgi.execute-api.us-west-1.amazonaws.com/dev/WMS_WebApp_AWS_Cognito_Delete_User' :  // dev
          appSettings.environment === "prod" ? 'https://knaapsdoag.execute-api.us-east-1.amazonaws.com/prod/VAS_WebApp_AWS_Cognito_Delete_User' :  // prod
            appSettings.environment === "ext" ? 'https://q3ck8q4508.execute-api.us-east-1.amazonaws.com/prod/WMS_EFW_WebApp_AWS_Cognito_Delete_User' :  // ext
              null
      ),


      AwsCognitoInviteUserAPI: (
        appSettings.environment === "dev" ? 'https://x6vw75xwgi.execute-api.us-west-1.amazonaws.com/dev/WMS_DEV_WebApp_AWS_Cognito_Invite_User' :  // dev
          appSettings.environment === "prod" ? 'https://knaapsdoag.execute-api.us-east-1.amazonaws.com/prod/VAS_WebApp_AWS_Cognito_Invite_User' :  // prod
            appSettings.environment === "ext" ? 'https://q3ck8q4508.execute-api.us-east-1.amazonaws.com/prod/WMS_EFW_WebApp_AWS_Cognito_Invite_User' :  // ext
              null
      ),








    }
  }
}

export const dbValues = {
  USCountryCodeID: 236
}

export const edi990QueueStatus = [
  {
    "label": "Pending",
    "value": 'W'
  },
  {
    "label": "Response Complete",
    "value": 'L'
  },
]

export const mawbStatus = [
  {
    "label": "Open",
    "value": 1
  },
  {
    "label": "Partial Received",
    "value": 2
  },
  {
    "label": "Received",
    "value": 3
  },
  {
    "label": "Closed",
    "value": 4
  },
]

export const containerStatus = [
  {
    "label": "Open",
    "value": 1
  },
  {
    "label": "Partial Received",
    "value": 2
  },
  {
    "label": "Closed",
    "value": 4
  },
]


export const orderStatus = [
  {
    "label": "Open",
    "value": 1
  },
  {
    "label": "Partially Picked",
    "value": 2
  },
  {
    "label": "Picked Complete",
    "value": 3
  },
  {
    "label": "Cancelled",
    "value": 4
  },
  {
    "label": "Closed",
    "value": 5
  },
]


export const ctrlTwrShipmentStatus = [
  {
    "label": "Open",
    "value": 0
  },
  {
    "label": "Complete",
    "value": 1
  }
]



export const ctrlTwrQuoteStatus = [
  {
    "label": "Pending",
    "value": 1
  },
  {
    "label": "Accepted",
    "value": 2
  },
  {
    "label": "Rejected",
    "value": 3
  }
]


export const printFormats = [
  {
    "label": "GIF",
    "value": "GIF",
  },
  {
    "label": "ZPL",
    "value": "ZPL",
  }
]



export const weightUnits = [
  {
    "label": "Lbs",
    "value": "Lbs",
  },
  {
    "label": "Kg",
    "value": "Kg",
  }
]

export const LabelGenWeightUnits = [
  {
    "label": "LBS",
    "value": "LBS",
  },
  {
    "label": "KGS",
    "value": "KGS",
  }
]

export const LabelGenLengthUnits = [
  {
    "label": "Inches",
    "value": "IN",
  },
  {
    "label": "Centimeters",
    "value": "CM",
  }
]

export const UpsPackagingCode = [
  {
    "label": "Customer Supplied Package",
    "value": "02",
  }
]



export const colorColumnsValuesDropDownFilter = [
  {
    "label": <div style={{ background: 'orange' }}> &nbsp; </div>,
    "value": 1
  },
  {
    "label": <div style={{ background: 'yellow' }}> &nbsp; </div>,
    "value": 2
  },
  {
    "label": <div style={{ background: 'green' }}> &nbsp; </div>,
    "value": 3
  },
  {
    "label": <div style={{ background: 'cyan' }}> &nbsp; </div>,
    "value": 4
  },
  {
    "label": <div style={{ background: 'blue' }}> &nbsp; </div>,
    "value": 5
  },
]



export const colorColumnsValues = [
  {
    "label": <div style={{ background: 'white' }}> &nbsp; </div>,
    "value": "null"
  },

  {
    "label": <div style={{ background: 'orange' }}> &nbsp; </div>,
    "value": 1
  },
  {
    "label": <div style={{ background: 'yellow' }}> &nbsp; </div>,
    "value": 2
  },
  {
    "label": <div style={{ background: 'green' }}> &nbsp; </div>,
    "value": 3
  },
  {
    "label": <div style={{ background: 'cyan' }}> &nbsp; </div>,
    "value": 4
  },
  {
    "label": <div style={{ background: 'blue' }}> &nbsp; </div>,
    "value": 5
  },

]


export const palletCaseSkuStatus = [
  {
    "label": "In Warehouse",
    "value": 1
  },
  {
    "label": "Partial Picked",
    "value": 2
  },
  {
    "label": "Picked",
    "value": 3
  },
  {
    "label": "Departed",
    "value": 4
  },
]

export const palletCaseSkuStatusWithDeleted = [
  {
    "label": "In Warehouse",
    "value": 1
  },
  {
    "label": "Partial Picked",
    "value": 2
  },
  {
    "label": "Picked",
    "value": 3
  },
  {
    "label": "Departed",
    "value": 4
  },
  {
    "label": "Deleted",
    "value": 5
  },
]

export const hawbStatus = [
  {
    "label": "In Warehouse",
    "value": 1
  },
  {
    "label": "Planned",
    "value": 2
  },
  {
    "label": "Departed",
    "value": 3
  },
]

export const loadStatus = [
  {
    "label": "Planned",
    "value": 1
  },
  {
    "label": "Departed",
    "value": 2
  },
  // {
  //     "label": "Delivered",
  //     "value": 3
  // },
  {
    "label": "Deleted",
    "value": 4
  },
]











export const customerAccountInfo = {
  EVO: {
    EvolveClientID: 'EVO',
    CustomerNumber: 'EVO',
    ReturnName: 'Return Name',
    ReturnAddressLine1: 'ReturnAddressLine1',
    ReturnAddressLine2: 'ReturnAddressLine2',
    ReturnCity: 'San Diego',
    ReturnState: 'CA',
    ReturnPostcode: '92109',
    ReturnCountryCode: 'US'
  },
  LUX: {
    EvolveClientID: 'LUX',
    CustomerNumber: 'LUX',
    ReturnName: 'Return Name',
    ReturnAddressLine1: 'ReturnAddressLine1',
    ReturnAddressLine2: 'ReturnAddressLine2',
    ReturnCity: 'San Diego',
    ReturnState: 'CA',
    ReturnPostcode: '92109',
    ReturnCountryCode: 'US'
  },
  MEX: {
    EvolveClientID: 'MEX',
    CustomerNumber: 'MEX',
    ReturnName: 'Return Name',
    ReturnAddressLine1: 'ReturnAddressLine1',
    ReturnAddressLine2: 'ReturnAddressLine2',
    ReturnCity: 'San Diego',
    ReturnState: 'CA',
    ReturnPostcode: '92109',
    ReturnCountryCode: 'US'
  },
  MXY: {
    EvolveClientID: 'MXY',
    CustomerNumber: 'MXY',
    ReturnName: 'Return Name',
    ReturnAddressLine1: 'ReturnAddressLine1',
    ReturnAddressLine2: 'ReturnAddressLine2',
    ReturnCity: 'San Diego',
    ReturnState: 'CA',
    ReturnPostcode: '92109',
    ReturnCountryCode: 'US'
  },
  VCN: {
    EvolveClientID: 'VCN',
    CustomerNumber: 'VCN',
    ReturnName: 'Return Name',
    ReturnAddressLine1: 'ReturnAddressLine1',
    ReturnAddressLine2: 'ReturnAddressLine2',
    ReturnCity: 'San Diego',
    ReturnState: 'CA',
    ReturnPostcode: '92109',
    ReturnCountryCode: 'US'
  },
  YIZ: {
    EvolveClientID: 'YIZ',
    CustomerNumber: 'YIZ',
    ReturnName: 'Return Name',
    ReturnAddressLine1: 'ReturnAddressLine1',
    ReturnAddressLine2: 'ReturnAddressLine2',
    ReturnCity: 'San Diego',
    ReturnState: 'CA',
    ReturnPostcode: '92109',
    ReturnCountryCode: 'US'
  },
}
